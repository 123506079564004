import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBoxOpen,
  faChartPie,
  faCog,
  faFileAlt,
  faHandHoldingUsd,
  faMagic,
  faSignOutAlt,
  faTable,
  faTimes,
  faCalendarAlt,
  faMapPin,
  faInbox,
  faRocket,
  faFileContract,
  faPlaceOfWorship,
  faMap,
  faGlobe,
  faQuestion,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
  Col,
  Card,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { RoutesCustom } from "../routescustom";
import ThemesbergLogo from "../assets/img/themesberg.svg";
import NugaLogo from "../assets/img/logo.png";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import moment from "moment-timezone";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const currentYear = moment().get("year");

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "d-flex justify-content-start align-items-center";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={12}
                height={12}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            {title != "" && <span className="sidebar-text">{title}</span>}
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={RoutesCustom.DashboardOverview.path}
        >
          <Image src={NugaLogo} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              {/* <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, Ragen</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={RoutesCustom.Signin.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div> */}
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>

            <Nav className="flex-column pt-3 pt-md-0">
              {/* <NavItem
                title=""
                // link={RoutesCustom.Presentation.path}
                image={NugaLogo}
              /> */}
              <Image
                src={NugaLogo}
                width={120}
                height={120}
                className="sidebar-icon svg-icon"
              />
              <NavItem
                title="Dashboard"
                link={RoutesCustom.DashboardOverview.path}
                icon={faChartPie}
              />
              {/* <CollapsableNavItem
                eventKey="examples/"
                title="Discover"
                icon={faMagic}
              >
                <NavItem
                  title="Categories"
                  link={RoutesCustom.categories.path}
                />
              </CollapsableNavItem> */}
              <NavItem
                title="Discover"
                icon={faMagic}
                link={RoutesCustom.categories.path}
              />
              <NavItem
                title="Heritage Walk"
                icon={faPlaceOfWorship}
                link={RoutesCustom.heritageWalk.path}
              />
              <NavItem
                title="Maps"
                icon={faMap}
                link={RoutesCustom.maps.path}
              />
              <NavItem
                title="Climate Emergency"
                icon={faGlobe}
                link={RoutesCustom.climateChange.path}
              />
              <NavItem
                title="Contact Us"
                icon={faFileContract}
                link={RoutesCustom.contactUs.path}
              />
              <NavItem
                title="Faqs"
                icon={faQuestionCircle}
                link={RoutesCustom.faqs.path}
              />
              <Dropdown.Divider className="my-3 border-indigo" />
              <CollapsableNavItem
                eventKey="tables/"
                title="Reports"
                icon={faTable}
              >
                <NavItem
                  title="Users Table"
                  link={RoutesCustom.BootstrapTables.path}
                />
                <NavItem
                  title="Categories"
                  link={RoutesCustom.BootstrapTables.path}
                />
              </CollapsableNavItem>
              {/* <CollapsableNavItem
                eventKey="examples/"
                title="Page Examples"
                icon={faFileAlt}
              >
                <NavItem title="Sign In" link={RoutesCustom.Signin.path} />
                <NavItem title="Sign Up" link={RoutesCustom.Signup.path} />
                <NavItem
                  title="Forgot password"
                  link={RoutesCustom.ForgotPassword.path}
                />
                <NavItem
                  title="Reset password"
                  link={RoutesCustom.ResetPassword.path}
                />
                <NavItem title="Lock" link={RoutesCustom.Lock.path} />
                <NavItem title="404 Not Found" link={RoutesCustom.NotFound.path} />
                <NavItem
                  title="500 Server Error"
                  link={RoutesCustom.ServerError.path}
                />
              </CollapsableNavItem> */}
              {/* <CollapsableNavItem
                eventKey="documentation/"
                title="Getting Started"
                icon={faBook}
              >
                <NavItem
                  title="Overview"
                  link={RoutesCustom.DocsOverview.path}
                />
                <NavItem
                  title="Download"
                  link={RoutesCustom.DocsDownload.path}
                />
                <NavItem
                  title="Quick Start"
                  link={RoutesCustom.DocsQuickStart.path}
                />
                <NavItem title="License" link={RoutesCustom.DocsLicense.path} />
                <NavItem
                  title="Folder Structure"
                  link={RoutesCustom.DocsFolderStructure.path}
                />
                <NavItem
                  title="Build Tools"
                  link={RoutesCustom.DocsBuild.path}
                />
                <NavItem
                  title="Changelog"
                  link={RoutesCustom.DocsChangelog.path}
                />
              </CollapsableNavItem> */}
              {/* <CollapsableNavItem
                eventKey="components/"
                title="Components"
                icon={faBoxOpen}
              >
                <NavItem
                  title="Accordion"
                  link={RoutesCustom.Accordions.path}
                />
                <NavItem title="Alerts" link={RoutesCustom.Alerts.path} />
                <NavItem title="Badges" link={RoutesCustom.Badges.path} />

                <NavItem
                  title="Breadcrumbs"
                  link={RoutesCustom.Breadcrumbs.path}
                />
                <NavItem title="Buttons" link={RoutesCustom.Buttons.path} />
                <NavItem title="Forms" link={RoutesCustom.Forms.path} />
                <NavItem title="Modals" link={RoutesCustom.Modals.path} />
                <NavItem title="Navbars" link={RoutesCustom.Navbars.path} />
                <NavItem title="Navs" link={RoutesCustom.Navs.path} />
                <NavItem
                  title="Pagination"
                  link={RoutesCustom.Pagination.path}
                />
                <NavItem title="Popovers" link={RoutesCustom.Popovers.path} />
                <NavItem title="Progress" link={RoutesCustom.Progress.path} />
                <NavItem title="Tables" link={RoutesCustom.Tables.path} />
                <NavItem title="Tabs" link={RoutesCustom.Tabs.path} />
                <NavItem title="Toasts" link={RoutesCustom.Toasts.path} />
                <NavItem title="Tooltips" link={RoutesCustom.Tooltips.path} />
              </CollapsableNavItem> */}
              <Button
                // as={Link}
                // to={RoutesCustom.Upgrade.path}
                variant="secondary"
                className="app-version btn-sm"
              >
                <FontAwesomeIcon icon={faRocket} className="me-1" /> Version
                1.0.0

                <Col xs={12} lg={12} className="mb-4 mb-lg-0">
                <p className="mb-0 text-center text-sm ">
                  Copyright © {`${currentYear} `}
                  <Card.Link
                    href="https://nuganepal.com"
                    target="_blank"
                    className="text-blue text-decoration-none fw-normal"
                  >
                    Nuga
                  </Card.Link>
                </p>
              </Col>
              </Button>{" "}
              
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
