import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";

import { RoutesCustom } from "../routescustom";
import { Link, useLocation } from "react-router-dom";
import { HeritageWalkStepsTable } from "../components/Tables";
import { connect } from "react-redux";
import { GetAllHeritageWalkSteps } from "../actions/actionCreaters/heritageWalkStepsActionCreater";

const HeritageWalkSteps = (props) => {
  const location = useLocation();

  useEffect(() => {
    props.loadHeritageWalkSteps(location.state.heritage_id);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <Link to={RoutesCustom.DashboardOverview.path}>
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {" "}
              <Link to={RoutesCustom.heritageWalk.path}>Heritage walk</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Heritage walk Steps</Breadcrumb.Item>
            {/* <Breadcrumb.Item active>SubCategories</Breadcrumb.Item> */}
          </Breadcrumb>
          <h4>Heritage Walk Steps</h4>
          <p className="mb-0">Add heritage walk steps.</p>
        </div>
      </div>
      <HeritageWalkStepsTable />
    </>
  );
};

const mapDispatchtoProps = (dispatch) => {
  return {
    loadHeritageWalkSteps: (heritage_id) =>
      dispatch(GetAllHeritageWalkSteps(heritage_id)),
  };
};
const mapStatetoProps = (state) => {
  return {
    heritageWalkState: state.heritageWalk,
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(HeritageWalkSteps);
