import { createStore, applyMiddleware } from "redux";
import { configStore, configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers";
import logger from "redux-logger";

const middleware = [thunk, logger];

const store = createStore(rootReducer, applyMiddleware(...middleware));
export default store;
