import { toast } from "react-toastify";

import {
  AddRequest,
  RemoveRequest,
  UpdateRequest,
  getAllRequestFail,
  getAllRequestSuccess,
  getbyIdSuccess,
  makeRequest,
} from "../faqsAction";
import faqsService from "../../services/faqs.service";

export const GetAllFAQs = () => {
  return (dispatch) => {
    dispatch(makeRequest());
    setTimeout(() => {
      faqsService
        .getAll()
        .then((res) => {
          const _list = res.data;
          dispatch(getAllRequestSuccess(_list));
        })
        .catch((err) => {
          dispatch(getAllRequestFail(err.message));
        });
    }, 1000);
  };
};

export const GetFAQById = (id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    faqsService
      .get(id)
      .then((res) => {
        const _obj = res.data;
        dispatch(getbyIdSuccess(_obj));
      })
      .catch((err) => {
        toast.error("Failed to fetch the data");
      });
  };
};

export const CreateFAQ = (data) => {
  return (dispatch) => {
    faqsService
      .create(data)
      .then((res) => {
        // dispatch(AddRequest(data));

        dispatch(GetAllFAQs());
        toast.success("FAQ created successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to create FAQ due to :" + message);
      });
  };
};

export const UpdateFAQ = (data, id) => {
  return (dispatch) => {
    faqsService
      .update(id, data)
      .then((res) => {
        // dispatch(UpdateRequest(res.data));
        dispatch(GetAllFAQs());
        toast.success("FAQ updated successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to update FAQ due to :" + message);
      });
  };
};

export const RemoveFAQ = (id, imageLink) => {
  return (dispatch) => {
    faqsService
      .delete(id, imageLink)
      .then((res) => {
        // dispatch(RemoveRequest(id));
        dispatch(GetAllFAQs());
        toast.success("FAQ removed successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to remove FAQ due to :" + message);
      });
  };
};
