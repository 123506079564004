import {
    MAKE_REQ,
    CREATE_FAQ,
    RETRIEVE_FAQS,
    REQ_GETALL_FAIL,
    UPDATE_FAQ,
    DELETE_FAQ,
    REQ_GETBYID_SUCC,

  } from "./types/faqType";
  
  export const makeRequest = () => {
    return {
      type: MAKE_REQ,
    };
  };
  
  export const getAllRequestSuccess = (data) => {
    return {
      type: RETRIEVE_FAQS,
      payload: data,
    };
  };
  
  export const getAllRequestFail = (err) => {
    return {
      type: REQ_GETALL_FAIL,
      payload: err,
    };
  };
  

  export const AddRequest = (data) => {
    return {
      type: CREATE_FAQ,
      payload: data,
    };
  };
  
  export const UpdateRequest = (data) => {
    return {
      type: UPDATE_FAQ,
      payload: data,
    };
  };
  
  export const RemoveRequest = (id) => {
    return {
      type: DELETE_FAQ,
      payload: id,
    };
  };
  
  export const getbyIdSuccess = (data) => {
    return {
      type: REQ_GETBYID_SUCC,
      payload: data,
    };
  };
  