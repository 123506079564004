import {
  MAKE_REQ,
  CREATE_DISCOVER,
  RETRIEVE_DISCOVER,
  UPDATE_DISCOVER,
  DELETE_DISCOVER,
  REQ_GETALL_SUCC,
  REQ_GETALL_FAIL,
  REQ_GETBYID_SUCC,
} from "../actions/types/discoverPlaceType";

const initialState = {
  isloading: false,
  discoverList: [],
  discoverObj: [{
    title: "",
    fullAddress: "",
    description: "",
    reference: "",
    longitude: "",
    latitude: "",
  }],
  errormessage: "",
};

function discoverReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MAKE_REQ:
      return {
        ...state,
        isloading: true,
      };
    case CREATE_DISCOVER:
      const _inputdata = { ...payload };
      // const _maxid = Math.max(...state.companylist.map(o => o.id));
      //     _inputdata.id = _maxid + 1;
      return {
        ...state,
        discoverList: [...state.discoverList, _inputdata],
      };
    case REQ_GETBYID_SUCC:
      return {
        ...state,
        isloading: false,
        discoverObj: payload,
      };
    case REQ_GETALL_SUCC:
      return {
        ...state,
        isloading: false,
        discoverList: payload,
      };

    case REQ_GETALL_FAIL:
      return {
        ...state,
        isloading: false,
        companylist: [],
        errormessage: payload,
      };

    case RETRIEVE_DISCOVER:
      return {
        ...state,
        isloading: false,
        discoverList: payload,
      };

    case UPDATE_DISCOVER:
      const _data = { ...action.payload };
      const _finaldata = state.discoverList.map((item) => {
        return item.id === _data.id ? _data : item;
      });
      return {
        ...state,
        discoverList: _finaldata,
      };

    case DELETE_DISCOVER:
      const _filterdata = state.discoverList.filter((data) => {
        return data.category_id !== payload;
      });
      return {
        ...state,
        discoverList: _filterdata,
      };

    default:
      return state;
  }
}

export default discoverReducer;
