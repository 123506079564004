import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCashRegister,
  faChartLine,
  faCloudUploadAlt,
  faMailBulk,
  faPlaceOfWorship,
  faPlus,
  faRocket,
  faRulerHorizontal,
  faTasks,
  faUser,
  faUserShield,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
} from "@themesberg/react-bootstrap";

import {
  CounterWidget,
  // CircleChartWidget,
  // BarChartWidget,
  TeamMembersWidget,
  ProgressTrackWidget,
  RankingWidget,
  // SalesValueWidget,
  // SalesValueWidgetPhone,
  AcquisitionWidget,
} from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import { connect, useSelector } from "react-redux";
import { GetAllDashboardStats } from "../../actions/actionCreaters/dashboardActionCreater";

const DashboardOverview = (props) => {
  useEffect(() => {
    props.loadDashboard();
  }, []);

  return props.dashboardState.isloading ? (
    <div>
      <h2>Loading.....</h2>
    </div>
  ) : props.dashboardState.errormessage ? (
    <div>
      <h2>{props.dashboardState.errormessage}</h2>
    </div>
  ) : (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Dropdown className="btn-toolbar">
          <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />New Task
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faTasks} className="me-2" /> New Task
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" /> Upload Files
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Preview Security
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Upgrade to Pro
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <ButtonGroup>
          <Button variant="outline-primary" size="sm">Share</Button>
          <Button variant="outline-primary" size="sm">Export</Button>
        </ButtonGroup> */}
      </div>
      {props.dashboardState.dashboardList && (
        <Row className="justify-content-md-center">
          <Col xs={12} className="mb-4 d-none d-sm-block"></Col>
          <Col xs={12} sm={6} xl={3} className="mb-4">
            <CounterWidget
              category="Users"
              title={props.dashboardState.dashboardList["users"]}
              period="Feb 1 - Apr 1"
              percentage={18.2}
              icon={faUser}
              iconColor="shape-secondary"
            />
          </Col>

          <Col xs={12} sm={6} xl={3} className="mb-4">
            <CounterWidget
              category="Discover Place"
              title={props.dashboardState.dashboardList["discovers"]}
              period="Feb 1 - Apr 1"
              percentage={18.2}
              icon={faPlaceOfWorship}
              iconColor="shape-secondary"
            />
          </Col>

          <Col xs={12} sm={6} xl={3} className="mb-4">
            <CounterWidget
              category="Heritage walk"
              title={props.dashboardState.dashboardList["heritages"]}
              period="Feb 1 - Apr 1"
              percentage={28.4}
              icon={faWalking}
              iconColor="shape-tertiary"
            />
          </Col>
          <Col xs={12} sm={6} xl={3} className="mb-4">
            <CounterWidget
              category="Contact"
              title={props.dashboardState.dashboardList["contacts"]}
              period="Feb 1 - Apr 1"
              percentage={28.4}
              icon={faMailBulk}
              iconColor="shape-tertiary"
            />
          </Col>

          {/* <Col xs={12} sm={6} xl={4} className="mb-4"> */}
          {/* <CircleChartWidget
            title="Traffic Share"
            data={trafficShares} />
        </Col> */}
        </Row>
      )}

      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  {/* <PageVisitsTable /> */}
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  {/* <TeamMembersWidget /> */}
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  {/* <ProgressTrackWidget /> */}
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  {/* <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders} /> */}
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  {/* <RankingWidget /> */}
                </Col>

                <Col xs={12} className="px-0">
                  {/* <AcquisitionWidget /> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const mapDispatchtoProps = (dispatch) => {
  return {
    loadDashboard: () => dispatch(GetAllDashboardStats()),
  };
};
const mapStatetoProps = (state) => {
  return {
    dashboardState: state.dashboard,
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(DashboardOverview);
