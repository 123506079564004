import React, { useState, useEffect } from "react";

import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";

import {
  districts,
  municipalities,
  province,
  wards,
} from "../../data/disctricts";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import {
  CreateHeritageWalk,
  UpdateHeritageWalk,
} from "../../actions/actionCreaters/heritageWalkActionCreater";
import { baseUrl, imgBaseUrl } from "../../services/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CreateHeritageWalkStep, UpdateHeritageWalkStep } from "../../actions/actionCreaters/heritageWalkStepsActionCreater";
import { useLocation } from "react-router-dom";
export const HeritageWalkStepsForm = ({
 
  isEdit,
  closepopup,
}) => {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [description, setDescription] = useState('');

  const [titleName, setTitleName] = useState();

  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();

  const dispatch = useDispatch();

  const editobj = useSelector((state) => state.heritageWalk.heritageWalkObj);
  const location = useLocation();

  const handlesubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("heritage_id", location.state.heritage_id);
    formData.append("step_name", titleName);
    formData.append("description", description);
    formData.append("longitude", longitude);
    formData.append("latitude", latitude);

    console.log(formData);
    if (isEdit != false) {
      dispatch(UpdateHeritageWalkStep(formData, editobj[0].heritage_step_id));
    } else {
      dispatch(CreateHeritageWalkStep(formData));
    }
    // closepopup();
  };

  useEffect(() => {
    if (Object.keys(editobj).length > 0 && isEdit) {
      setTitleName(editobj[0].step_name);
      setLongitude(editobj[0].longitude);
      setLatitude(editobj[0].latitude);
      setDescription(editobj[0].description);
    } else {
    }
  }, [editobj]);

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4 w-100">
        <Card.Body>
          <h5 className="mb-4">General information</h5>
          <Form onSubmit={handlesubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="subCategory">
                  <Form.Label>Heritage Place name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your heritage place name"
                    name="title"
                    value={titleName}
                    onChange={(e) => {
                      setTitleName(e.target.value);
                    }}
                    autoComplete="on"
                  />
                </Form.Group>
              </Col>
            </Row>

            <h5 className="mb-4">Description</h5>

            <Col>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                config={{
                  ckfinder: {
                    uploadUrl: baseUrl + "/ckeditor/upload",
                  },
                }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                name="description"
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // setDiscoverFormData({ description: data });
                  setDescription(data);
                }}
              />

              <h5 className="mb-4 mt-4">Map Position</h5>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="longitude">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="28.3949"
                      name="longitude"
                      value={longitude}
                      onChange={(e) => {
                        setLongitude(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="latitude">
                    <Form.Label>Latitdue</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="84.1240"
                      name="latitude"
                      value={latitude}
                      onChange={(e) => {
                        setLatitude(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                <Button variant="primary" type="submit">
                  {isEdit ? "Edit" : "Save"}
                </Button>
              </div>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default HeritageWalkStepsForm;
