import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faGoogle,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { RoutesCustom } from "../../routescustom";
import BgImage from "../../assets/img/illustrations/wave_bg.svg";

import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { login, logout } from "../../actions/auth";
import { isEmail } from "validator";
import authService from "../../services/auth.service";

export default () => {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const [email, setEmail] = useState("ragenmah99@gmail.com");
  const [password, setPassword] = useState("Ragen@123");

  const form = useRef();
  const checkBtn = useRef();

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);
    // form.current.validateAll();
    // alert(isLoggedIn);

    dispatch(login(email, password));

    // const response = authService.login(email, password);
    // response.then(navigate(RoutesCustom.DashboardOverview.path)).catch();
    // if (response) {
    //   // console.log(response);
    // }
    setLoading(false);
    // window.location.reload();

    // return <Navigate to={RoutesCustom.DashboardOverview.path} />;
  };

  if (isLoggedIn) {
    return <Navigate to={RoutesCustom.DashboardOverview.path} />;
  }

  // useEffect(() => {
  // if (user) {
  //   axios
  //     .get(
  //       `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user.access_token}`,
  //           Accept: "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       navigate.push(RoutesCustom.DashboardOverview.path);
  //       // <Navigate to={RoutesCustom.DashboardOverview.path} />;
  //       // alert(res.data);
  //       setProfile(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }
  // }, [user]);

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const isEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };

  return (
    <main
      className="form-bg-image"
      // style={{ backgroundImage: `url(${BgImage})`, width: "100vw" }}
    >
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            {/* <Card.Link
              as={Link}
              to={RoutesCustom.DashboardOverview.path}
              className="text-gray-700"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
              homepage
            </Card.Link> */}
          </p>
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <Row className="justify-content-center ">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <img></img>
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-4" onSubmit={handleLogin}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        // validations={[required, isEmail]}
                        // validations={[required]}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          // validations={[required]}
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete="off"
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Remember me
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">
                        Lost password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                  </Button>
                </Form>

                <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-facebook me-2"
                    onClick={() => loginGoogle()}
                  >
                    <FontAwesomeIcon icon={faGoogle} />
                  </Button>
                </div>
                {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={RoutesCustom.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>

        {/* <div>
          <h2>React Google Login</h2>
          <br />
          <br />
          {profile ? (
            <div>
              <img src={profile.picture} alt="user image" />
              <h3>User Logged in</h3>
              <p>Name: {profile.name}</p>
              <p>Email Address: {profile.email}</p>
              <br />
              <br />
              <button onClick={logOut}>Log out</button>
            </div>
          ) : (
            <button onClick={() => login()}>Sign in with Google 🚀 </button>
          )}
          <button onClick={() => login()}>Sign in with Google 🚀 </button>
        </div> */}
      </section>
    </main>
  );
};

// export default ;
