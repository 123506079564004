import React, { useState, useEffect } from "react";
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";
import { Col, Row, Button, Dropdown } from "@themesberg/react-bootstrap";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { Modal } from "@themesberg/react-bootstrap";
import CategoryForm from "./forms/CategoryForm";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { RoutesCustom } from "../routescustom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  GetAllCategories,
  GetCategoryById,
  RemoveCategory,
} from "../actions/actionCreaters/categoriesActionCreater";
import { imgBaseUrl } from "../services/api";

const Categories = (props) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [isedit, iseditchange] = useState(false);
  const [title, titlechange] = useState("Create Category");

  //preparing for deleting image
  const [id, SetId] = useState(null);
  const [imageLink, SetImageLink] = useState(null);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const closepopup = () => {
    setShowDefault(false);
    setShowDelete(false);
  };

  const handleEdit = (id) => {
    iseditchange(true);
    titlechange("Update Category");
    setShowDefault(true);
    dispatch(GetCategoryById(id));
  };

  const handleListSubCategory = (id) => {
    // dispatch(GetCategoryById(id));

    navigate(RoutesCustom.SubCategories.path, {
      state: { category_id: id },
    });
  };

  const handleRemove = () => {
    // alert(imageLink)
    dispatch(RemoveCategory(id, imageLink));
    handleCloseDelete();
  };

  const handleShowDelete = (id, imageLink) => {
    setShowDelete(true);
    SetId(id);
    SetImageLink(JSON.stringify(imageLink));
  };

  useEffect(() => {
    props.loadcategories();
  }, []);

  return props.categorystate.isloading ? (
    <div>
      <h2>Loading.....</h2>
    </div>
  ) : props.categorystate.errormessage ? (
    <div>
      <h2>{props.categorystate.errormessage}</h2>
    </div>
  ) : (
    <>
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">{title}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <CategoryForm isedit={isedit} closepopup={closepopup} />
        </Modal.Body>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Header>
          <Modal.Title className="h6">Are your sure ?</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>Once delete it will not be able to recover.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" aria-label="Yes" onClick={handleRemove}>
            Yes
          </Button>
          <Button
            variant="outline-primary"
            aria-label="No"
            onClick={handleCloseDelete}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <h2>Categories</h2>
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="card">
            <img
              className="card-img-top"
              src="https://images.pexels.com/photos/267569/pexels-photo-267569.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt=""
              height={270}
            />
            <div className="card-body">
              <p className="h6">
                <small className="text-muted">Discover</small>
                <br />
                Add new places
              </p>
            </div>
            <div className="card-footer p-0">
              <div className="btn-group d-flex" role="group">
                <button
                  type="button"
                  className="btn btn-light bg-info text-white"
                  onClick={() => setShowDefault(true)}
                >
                  <i className="fas fa-plus"></i>
                  <span>Add new</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {props.categorystate.categoryList &&
          props.categorystate.categoryList.map((row, i) => {
            return (
              <div className="col-md-4 mb-4" key={i}>
                <div className="card">
                  <img
                    className="card-img-top"
                    // src="https://images.pexels.com/photos/11507730/pexels-photo-11507730.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    src={imgBaseUrl + row.category_image}
                    alt=""
                    height={270}
                  />
                  <div className="card-body">
                    <p className="h6">
                      <small className="text-muted">Name</small>
                      <br />
                      {row.category_name}
                    </p>
                  </div>
                  <div className="card-footer p-0">
                    <div className="btn-group d-flex" role="group">
                      <button
                        type="button"
                        className="btn btn-light bg-info text-white"
                        onClick={() => handleEdit(row.category_id)}
                      >
                        <i className="fas fa-edit"></i>
                        <span>Edit</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-light bg-danger text-white"
                        onClick={() =>
                          handleShowDelete(row.category_id, row.category_image)
                        }
                      >
                        <i className="fas fa-trash"></i>
                        <span>Delete</span>
                      </button>
                      {/* <button
                  type="button"
                  className="btn btn-light bg-primary text-white"
                >
                  <i className="fas fa-plus"></i>
                  <span>Add Sub</span>
                </button> */}

                      <button
                        type="button"
                        className="btn btn-light bg-primary text-white"
                        onClick={() => handleListSubCategory(row.category_id)}
                      >
                        {/* <Link to={RoutesCustom.SubCategories.path}> */}
                        <i className="fas fa-list"></i>
                        {/* </Link> */}

                        <span>list</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
const mapDispatchtoProps = (dispatch) => {
  return {
    loadcategories: () => dispatch(GetAllCategories()),
  };
};
const mapStatetoProps = (state) => {
  return {
    categorystate: state.category,
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Categories);
