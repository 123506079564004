import {
  MAKE_REQ,
  CREATE_CATEGORY,
  RETRIEVE_CATEGORIES,
  REQ_GETALL_FAIL,
  OPEN_POPUP,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  REQ_GETBYID_SUCC,
} from "./types/categoriesType";

export const makeRequest = () => {
  return {
    type: MAKE_REQ,
  };
};

export const getAllRequestSuccess = (data) => {
  return {
    type: RETRIEVE_CATEGORIES,
    payload: data,
  };
};

export const getAllRequestFail = (err) => {
  return {
    type: REQ_GETALL_FAIL,
    payload: err,
  };
};

export const OpenPopup = () => {
  return {
    type: OPEN_POPUP,
  };
};

export const AddRequest = (data) => {
  return {
    type: CREATE_CATEGORY,
    payload: data,
  };
};

export const UpdateRequest = (data) => {
  return {
    type: UPDATE_CATEGORY,
    payload: data,
  };
};

export const RemoveRequest = (id) => {
  return {
    type: DELETE_CATEGORY,
    payload: id,
  };
};

export const getbyIdSuccess = (data) => {
  return {
    type: REQ_GETBYID_SUCC,
    payload: data,
  };
};
