import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";
// import Editor from "../../components/Editor";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import {
  districts,
  municipalities,
  province,
  wards,
} from "../../data/disctricts";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateDiscover,
  UpdateDiscover,
} from "../../actions/actionCreaters/discoverActionCreater";
import { baseUrl, imgBaseUrl } from "../../services/api";

export const DiscoverPlacesForm = ({ subCatId, isedit, title }) => {
  const [editorLoaded, setEditorLoaded] = useState(false);
  // const [data, setData] = useState("");

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // const [isedit, iseditchange] = useState(false);
  // const [title, titlechange] = useState("Create New Place");

  const [titleName, setTitleName] = useState();
  const [fullAddress, setFullAddress] = useState();
  // const [province, setProvince] = useState();
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState();
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();

  const [selectedProvince, setSelectedProvince] = useState(province[2].name);
  const [selectedDistrict, setSelectedDistrict] = useState(districts[5].name);
  const [selectedMunicipality, setMunicipality] = useState(
    municipalities[0].name
  );
  const [selectedWard, setSelectedWard] = useState("1");

  const [districtItems, setDistrict] = useState([]);
  const [municipalityItems, setMunicipalities] = useState([]);
  const [wardItems, setWardItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setMunicipalities([]); // reset select
    // setMunicipality(""); // reset select
    setDistrict([]);
    selectedProvince &&
      setDistrict(districts.filter((c) => c.province === selectedProvince));
  }, [selectedProvince]);

  useEffect(() => {
    setMunicipalities([]); // reset select
    // setMunicipality(""); // reset select

    selectedDistrict &&
      setMunicipalities(
        municipalities.filter((c) => c.district === selectedDistrict)
      );
  }, [selectedDistrict]);

  useEffect(() => {
    selectedMunicipality &&
      setWardItems(
        wards.filter((c) => c.municipality === selectedMunicipality)
      );
  }, [selectedMunicipality]);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   axios
  //     .post("http://localhost:8000/posts", data)
  //     .then(setdescription(""))
  //     .catch((err) => console.log(err));
  // }

  const editobj = useSelector((state) => state.discover.discoverObj);

  const handlesubmit = (e) => {
    e.preventDefault();
    if (isedit == false && imagePreview == null) {
      toast.warn("Discover place image canot be empty.");
      return;
    }

    let formData = new FormData();
    formData.append("sub_category_id", subCatId);
    formData.append("title", titleName);
    formData.append("fullAddress", fullAddress);
    formData.append("province", selectedProvince);
    formData.append("district", selectedDistrict);
    formData.append("municipality", selectedMunicipality);
    formData.append("ward", selectedWard);
    formData.append("posted_by", "Admin");
    formData.append("description", description);
    formData.append("reference", reference);
    formData.append("longitude", longitude);
    formData.append("latitude", latitude);

    if (isedit != false && image == null) {
      formData.append("image_file_name", editobj[0].image_file_name);
    } else {
      formData.append("image", image, image.name);
    }
    // alert(formData["category_name"]);
    console.log(formData);
    if (isedit != false) {
      formData.append("discover_id", editobj[0].discover_id);
      dispatch(UpdateDiscover(formData, editobj[0].discover_id));
    } else {
      dispatch(CreateDiscover(formData));
    }
    // closepopup();
  };

  // const [discoverFormData, setDiscoverFormData] = useState({
  //   title: "",
  //   fullAddress: "",
  //   description: "",
  //   reference: "",
  //   longitude: "",
  //   latitude: "",
  // });

  // const handleChange = (e) => {
  //   setDiscoverFormData({
  //     ...discoverFormData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleFileInput = (e) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    if (Object.keys(editobj).length > 0 && isedit) {
      // setDiscoverFormData({
      //   title: editobj[0].title,
      //   fullAddress: editobj[0].fullAddress,
      //   description: editobj[0].description,
      //   reference: editobj[0].reference,
      //   longitude: editobj[0].longitude,
      //   latitude: editobj[0].latitude,
      // });
      setTitleName(editobj[0].title);
      setFullAddress(editobj[0].fullAddress);
      setDescription(editobj[0].description);
      setReference(editobj[0].reference);
      setLongitude(editobj[0].longitude);
      setLatitude(editobj[0].latitude);
      setSelectedProvince(editobj[0].province);
      setSelectedDistrict(editobj[0].district);
      setMunicipality(editobj[0].municipality);
      setSelectedWard(editobj[0].ward);
      setImagePreview(imgBaseUrl + editobj[0].image_file_name);
    } else {
      // clearstate();
    }
  }, [editobj]);

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4 w-100">
        <Card.Body>
          <h5 className="mb-4">General information</h5>
          <Form onSubmit={handlesubmit}>
            {/* {selectedWard}
            {selectedDistrict}
            {selectedMunicipality} */}
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="subCategory">
                  <Form.Label>Place name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your Place name"
                    name="title"
                    value={titleName}
                    onChange={(e) => {
                      setTitleName(e.target.value);
                    }}
                    autoComplete="on"
                  />
                </Form.Group>
              </Col>
            </Row>
            <ChoosePhotoWidget
              title="Select place picture"
              photo={
                imagePreview ??
                "https://ragenmah.github.io/nuga-eco-club/static/images/logo.png"
              }
              setImage={setImage}
              handleFileInput={handleFileInput}
            />

            <h5 className="mb-4">Description</h5>
            {/* <Editor
              name="description"
              onChange={(data) => {
                setData(data);
              }}
              editorLoaded={editorLoaded}
            /> */}

            <CKEditor
              editor={ClassicEditor}
              data={description}
              config={{
                ckfinder: {
                  uploadUrl: baseUrl + "/ckeditor/upload",
                },
              }}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              name="description"
              onChange={(event, editor) => {
                const data = editor.getData();
                // setDiscoverFormData({ description: data });
                setDescription(data);
              }}
            />
            {/* {JSON.stringify(description)} */}
            <br></br>
            <Col>
              <h5 className="mb-4">Address</h5>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="full location">
                    <Form.Label>Full Location</Form.Label>
                    <Form.Control
                      required
                      placeholder="Kathmandu, kirtipur"
                      name="fullAddress"
                      // value={discoverFormData.fullAddress}
                      // onChange={handleChange}
                      value={fullAddress}
                      onChange={(e) => {
                        setFullAddress(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Group className="mb-2">
                  <Form.Label>Select Province</Form.Label>
                  <Form.Select
                    id="province"
                    value={selectedProvince.id}
                    onChange={(e) => setSelectedProvince(e.target.value)}
                    defaultValue={selectedProvince.id}
                  >
                    {province.map((p) => (
                      <option
                        key={p.id}
                        value={p.name}
                        name={p.name}
                        selected={
                          isedit
                            ? p.name == editobj[0].province
                              ? true
                              : false
                            : p.name == province[2].name
                            ? true
                            : false
                        }
                      >
                        {p.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Col sm={4} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Select District</Form.Label>
                    <Form.Select
                      id="district"
                      value={districtItems.id}
                      onChange={(e) => setSelectedDistrict(e.target.value)}
                      defaultValue={districtItems.id}
                    >
                      {districtItems.map((p) => (
                        <option
                          key={p.id}
                          value={p.name}
                          name={p.name}
                          selected={
                            isedit
                              ? p.name == editobj[0].district
                                ? true
                                : false
                              : p.name == districtItems[5].name
                              ? true
                              : false
                          }
                        >
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={4} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Municipality</Form.Label>
                    <Form.Select
                      id="municipality"
                      onChange={(e) => setMunicipality(e.target.value)}
                    >
                      {municipalityItems.map((c) => (
                        <option
                          key={c.id}
                          value={c.name}
                          name={c.name}
                          selected={
                            isedit
                              ? c.name == editobj[0].municipality
                                ? true
                                : false
                              : true
                          }
                        >
                          {c.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-2">
                    <Form.Label>Ward</Form.Label>
                    <Form.Select
                      id="ward"
                      onChange={(e) => setSelectedWard(e.target.value)}
                    >
                      {wards.map((c) => (
                        <option
                          key={c.id}
                          value={c.name}
                          name={c.name}
                          selected={
                            isedit
                              ? c.name == editobj[0].ward
                                ? true
                                : false
                              : true
                          }
                        >
                          {c.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="reference">
                    <Form.Label>Reference from</Form.Label>
                    <Form.Control
                      // required
                      type="text"
                      placeholder="Reference"
                      name="reference"
                      value={reference}
                      onChange={(e) => {
                        setReference(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <h5 className="mb-4">Map Position</h5>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="longitude">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="28.3949"
                      name="longitude"
                      value={longitude}
                      onChange={(e) => {
                        setLongitude(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="latitude">
                    <Form.Label>Latitdue</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="84.1240"
                      name="latitude"
                      value={latitude}
                      onChange={(e) => {
                        setLatitude(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                <Button variant="primary" type="submit">
                  {isedit ? "Edit" : "Save"}
                </Button>
              </div>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default DiscoverPlacesForm;
