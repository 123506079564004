import {
    MAKE_REQ,
    CREATE_HERITAGE_WALK,
    RETRIEVE_HERITAGE_WALK,
    REQ_GETALL_FAIL,
    UPDATE_HERITAGE_WALK,
    DELETE_HERITAGE_WALK,
    REQ_GETBYID_SUCC,

  } from "./types/heritageWalksType";
  
  export const makeRequest = () => {
    return {
      type: MAKE_REQ,
    };
  };
  
  export const getAllRequestSuccess = (data) => {
    return {
      type: RETRIEVE_HERITAGE_WALK,
      payload: data,
    };
  };
  
  export const getAllRequestFail = (err) => {
    return {
      type: REQ_GETALL_FAIL,
      payload: err,
    };
  };
  

  export const AddRequest = (data) => {
    return {
      type: CREATE_HERITAGE_WALK,
      payload: data,
    };
  };
  
  export const UpdateRequest = (data) => {
    return {
      type: UPDATE_HERITAGE_WALK,
      payload: data,
    };
  };
  
  export const RemoveRequest = (id) => {
    return {
      type: DELETE_HERITAGE_WALK,
      payload: id,
    };
  };
  
  export const getbyIdSuccess = (data) => {
    return {
      type: REQ_GETBYID_SUCC,
      payload: data,
    };
  };
  