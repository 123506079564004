import { toast } from "react-toastify";

import {
  getAllRequestFail,
  getAllRequestSuccess,
  makeRequest,
} from "../dashboardAction";
import dashboardService from "../../services/dashboard.service";

export const GetAllDashboardStats = () => {
  return (dispatch) => {
    dispatch(makeRequest());
    setTimeout(() => {
      dashboardService
        .getAll()
        .then((res) => {
          const _list = res.data;
          dispatch(getAllRequestSuccess(_list));
        })
        .catch((err) => {
          dispatch(getAllRequestFail(err.message));
        });
    }, 1000);
  };
};
