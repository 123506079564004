import {
  MAKE_REQ,
  CREATE_CATEGORY,
  RETRIEVE_CATEGORIES,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  DELETE_ALL_CATEGORIES,
  REQ_GETALL_SUCC,
  REQ_GETALL_FAIL,
  REQ_GETBYID_SUCC,
} from "../actions/types/categoriesType";

const initialState = {
  isloading: false,
  categoryList: [],
  categoryObj: {},
  errormessage: "",
};

function categoryReducer(categories = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MAKE_REQ:
      return {
        ...categories,
        isloading: true,
      };
    case CREATE_CATEGORY:
      const _inputdata = { ...payload };
      // const _maxid = Math.max(...state.companylist.map(o => o.id));
      //     _inputdata.id = _maxid + 1;
      return {
        ...categories,
        categoryList: [...categories.categoryList, _inputdata],
      };
    case REQ_GETBYID_SUCC:
      return {
        ...categories,
        isloading: false,
        categoryObj: payload,
      };
    case REQ_GETALL_SUCC:
      return {
        ...categories,
        isloading: false,
        categoryList: payload,
      };

    case REQ_GETALL_FAIL:
      return {
        ...categories,
        isloading: false,
        companylist: [],
        errormessage: payload,
      };

    case RETRIEVE_CATEGORIES:
      return {
        ...categories,
        isloading: false,
        categoryList: payload,
      };

    case UPDATE_CATEGORY:
      const _data = { ...action.payload };
      const _finaldata = categories.categoryList.map((item) => {
        return item.id === _data.id ? _data : item;
      });
      return {
        ...categories,
        categoryList: _finaldata,
      };

    case DELETE_CATEGORY:
      const _filterdata = categories.categoryList.filter((data) => {
        return data.category_id !== payload;
      });
      return {
        ...categories,
        categoryList: _filterdata,
      };

    case DELETE_ALL_CATEGORIES:
      return [];

    default:
      return categories;
  }
}

export default categoryReducer;
