import {
    MAKE_REQ,
    CREATE_DISCOVER,
    RETRIEVE_DISCOVER,
    REQ_GETALL_FAIL,

    UPDATE_DISCOVER,
    DELETE_DISCOVER,
    REQ_GETBYID_SUCC,
  } from "./types/discoverPlaceType";
  
  export const makeRequest = () => {
    return {
      type: MAKE_REQ,
    };
  };
  
  export const getAllRequestSuccess = (data) => {
    return {
      type: RETRIEVE_DISCOVER,
      payload: data,
    };
  };
  
  export const getAllRequestFail = (err) => {
    return {
      type: REQ_GETALL_FAIL,
      payload: err,
    };
  };
  

  
  export const AddRequest = (data) => {
    return {
      type: CREATE_DISCOVER,
      payload: data,
    };
  };
  
  export const UpdateRequest = (data) => {
    return {
      type: UPDATE_DISCOVER,
      payload: data,
    };
  };
  
  export const RemoveRequest = (id) => {
    return {
      type: DELETE_DISCOVER,
      payload: id,
    };
  };
  
  export const getbyIdSuccess = (data) => {
    return {
      type: REQ_GETBYID_SUCC,
      payload: data,
    };
  };
  