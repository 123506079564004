import {
  MAKE_REQ,
  RETRIEVE_CONTACT_US,
  REQ_GETALL_FAIL,
  DELETE_CONTACT_US,
  REQ_GETBYID_SUCC,
} from "./types/contactUsType";

export const makeRequest = () => {
  return {
    type: MAKE_REQ,
  };
};

export const getAllRequestSuccess = (data) => {
  return {
    type: RETRIEVE_CONTACT_US,
    payload: data,
  };
};

export const getAllRequestFail = (err) => {
  return {
    type: REQ_GETALL_FAIL,
    payload: err,
  };
};

export const RemoveRequest = (id) => {
  return {
    type: DELETE_CONTACT_US,
    payload: id,
  };
};

export const getbyIdSuccess = (data) => {
  return {
    type: REQ_GETBYID_SUCC,
    payload: data,
  };
};
