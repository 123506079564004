import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";

import { useDispatch, useSelector } from "react-redux";
import {
  CreateCategory,
  UpdateCategory,
} from "../../actions/actionCreaters/categoriesActionCreater";
import { toast } from "react-toastify";
import { imgBaseUrl } from "../../services/api";

export const CategoryForm = ({ isedit ,closepopup}) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [categoryFormData, setCategoryFormData] = useState({
    category_name: "",
  });

  const editobj = useSelector((state) => state.category.categoryObj);

  useEffect(() => {
    if (Object.keys(editobj).length > 0) {
      // categoryFormData.category_name = editobj[0].category_name;
      setCategoryFormData({ category_name: editobj[0].category_name });
      setImagePreview(imgBaseUrl + editobj[0].category_image);
    } else {
      // clearstate();
    }
  }, [editobj]);

  const clearstate = () => {
    setCategoryFormData({ category_name: "" });
  };

  const handleFileInput = (e) => {
    // alert(e.target.files[0]);
    // const formData = new FormData();
    // formData.append("image", e.target.files[0], e.target.files[0].name);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    if (isedit == false && imagePreview == null) {
      toast.warn("Category image canot be empty.");
      return;
    }

    // const _obj = categoryFormData;
    let formData = new FormData();
    formData.append("category_name", categoryFormData.category_name);

    if (isedit != false && image == null) {
      formData.append("image", editobj[0].category_image);
    } else {
      formData.append("image", image, image.name);
    }
    // alert(formData["category_name"]);
    console.log(formData);
    if (isedit != false) {
      formData.append("category_id", editobj[0].category_id);
      dispatch(UpdateCategory(formData));
    } else {
      dispatch(CreateCategory(formData));
    }
    closepopup();
  };

  const handleChange = (e) => {
    setCategoryFormData({
      ...categoryFormData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4 w-100">
        <Card.Body>
          <h5 className="mb-4">General information</h5>
          <Form onSubmit={handlesubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your Category name"
                    name="category_name"
                    value={categoryFormData.category_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <ChoosePhotoWidget
              title="Select category picture"
              photo={
                imagePreview ??
                "https://ragenmah.github.io/nuga-eco-club/static/images/logo.png"
              }
              setImage={setImage}
              handleFileInput={handleFileInput}
            />
            <div className="mt-3">
              <Button variant="primary" type="submit">
                {isedit ? "Edit" : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default CategoryForm;
