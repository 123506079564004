import { http, httpFile } from "../http-common";

class HeritageWalkService {
  getAll() {
    return http.get("/heritage-walk-steps");
  }

  get(id) {
    return http.get(`/heritage-walk-steps/${id}`);
  }

  getByHeritageWalkId(id) {
    return http.get(`/heritage-walk-steps/heritage-walk/${id}`);
  }

  create(data) {
    return http.post("/heritage-walk-steps", data);
  }

  update(id, data) {
    return http.put(`/heritage-walk-steps/${id}`, data);
  }

  delete(id,) {
    return http.delete(`/heritage-walk-steps/${id}`);
  }

  deleteAll() {
    return http.delete(`/heritage-walk-steps`);
  }
}

export default new HeritageWalkService();
