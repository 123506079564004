import {
  MAKE_REQ,
  RETRIEVE_CONTACT_US,
  DELETE_CONTACT_US,
  REQ_GETALL_SUCC,
  REQ_GETALL_FAIL,
  REQ_GETBYID_SUCC,
} from "../actions/types/contactUsType";

const initialState = {
  isloading: false,
  contactList: [],
  contactObj: {name:''},
  errormessage: "",
};

function contactUsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MAKE_REQ:
      return {
        ...state,
        isloading: true,
      };

    case REQ_GETBYID_SUCC:
      return {
        ...state,
        isloading: false,
        contactObj: payload,
      };

    case REQ_GETALL_SUCC:
      return {
        ...state,
        isloading: false,
        contactList: payload,
      };

    case REQ_GETALL_FAIL:
      return {
        ...state,
        isloading: false,
        companylist: [],
        errormessage: payload,
      };

    case RETRIEVE_CONTACT_US:
      return {
        ...state,
        isloading: false,
        contactList: payload,
      };

    case DELETE_CONTACT_US:
      const _filterdata = state.contactList.filter((data) => {
        return data.category_id !== payload;
      });
      return {
        ...state,
        contactList: _filterdata,
      };

    default:
      return state;
  }
}

export default contactUsReducer;
