import { http, httpFile } from "../http-common";

class ContactUsService {
  getAll() {
    return http.get("/contactUs");
  }

  get(id) {
    return http.get(`/contactUs/${id}`);
  }

  delete(id) {
    return httpFile.delete(`/contactUs/${id}`);
  }

  deleteAll() {
    return http.delete(`/contactUs`);
  }
}

export default new ContactUsService();
