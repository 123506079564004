import React, { useState, useEffect } from "react";

import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";

import {
  districts,
  municipalities,
  province,
  wards,
} from "../../data/disctricts";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import {
  CreateHeritageWalk,
  UpdateHeritageWalk,
} from "../../actions/actionCreaters/heritageWalkActionCreater";
import { imgBaseUrl } from "../../services/api";

export const HeritageWalkForm = ({ heritageWalkId, isEdit, closepopup }) => {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [titleName, setTitleName] = useState();

  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();

  const [selectedProvince, setSelectedProvince] = useState(province[2].name);
  const [selectedDistrict, setSelectedDistrict] = useState(districts[5].name);
  const [selectedMunicipality, setMunicipality] = useState(
    municipalities[0].name
  );
  const [selectedWard, setSelectedWard] = useState("1");

  const [districtItems, setDistrict] = useState([]);
  const [municipalityItems, setMunicipalities] = useState([]);
  const [wardItems, setWardItems] = useState([]);

  const dispatch = useDispatch();

  const editobj = useSelector((state) => state.heritageWalk.heritageWalkObj);

  useEffect(() => {
    setMunicipalities([]); // reset select
    // setMunicipality(""); // reset select
    setDistrict([]);
    selectedProvince &&
      setDistrict(districts.filter((c) => c.province === selectedProvince));
  }, [selectedProvince]);

  useEffect(() => {
    setMunicipalities([]); // reset select
    // setMunicipality(""); // reset select

    selectedDistrict &&
      setMunicipalities(
        municipalities.filter((c) => c.district === selectedDistrict)
      );
  }, [selectedDistrict]);

  useEffect(() => {
    selectedMunicipality &&
      setWardItems(
        wards.filter((c) => c.municipality === selectedMunicipality)
      );
  }, [selectedMunicipality]);

  const handlesubmit = (e) => {
    e.preventDefault();
    if (isEdit == false && imagePreview == null) {
      toast.warn("Heritage place image canot be empty.");
      return;
    }

    let formData = new FormData();
    formData.append("place_name", titleName);
    formData.append("province", selectedProvince);
    formData.append("district", selectedDistrict);
    formData.append("municipality", selectedMunicipality);
    formData.append("ward", selectedWard);
    formData.append("center_longitude", longitude);
    formData.append("center_latitude", latitude);

    if (isEdit != false && image == null) {
      formData.append("image_file_name", editobj[0].place_img);
    } else {
      formData.append("image", image, image.name);
    }
    // alert(formData["category_name"]);
    console.log(formData);
    if (isEdit != false) {
      dispatch(UpdateHeritageWalk(formData, editobj[0].heritage_id));
    } else {
      dispatch(CreateHeritageWalk(formData));
    }
    closepopup();
  };

  const handleFileInput = (e) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    if (Object.keys(editobj).length > 0 && isEdit) {
      setTitleName(editobj[0].place_name);
      setLongitude(editobj[0].center_longitude);
      setLatitude(editobj[0].center_latitude);
      setSelectedProvince(editobj[0].province);
      setSelectedDistrict(editobj[0].district);
      setMunicipality(editobj[0].municipality);
      setSelectedWard(editobj[0].ward);
      setImagePreview(imgBaseUrl + editobj[0].place_img);
    } else {
    }
  }, [editobj]);

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4 w-100">
        <Card.Body>
          <h5 className="mb-4">General information</h5>
          <Form onSubmit={handlesubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="subCategory">
                  <Form.Label>Heritage Place name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your heritage place name"
                    name="title"
                    value={titleName}
                    onChange={(e) => {
                      setTitleName(e.target.value);
                    }}
                    autoComplete="on"
                  />
                </Form.Group>
              </Col>
            </Row>
            <ChoosePhotoWidget
              title="Select place picture"
              photo={
                imagePreview ??
                "https://ragenmah.github.io/nuga-eco-club/static/images/logo.png"
              }
              setImage={setImage}
              handleFileInput={handleFileInput}
            />

            <Col>
              <Row className="mb-3">
                <Form.Group className="mb-2">
                  <Form.Label>Select Province</Form.Label>
                  <Form.Select
                    id="province"
                    value={selectedProvince.id}
                    onChange={(e) => setSelectedProvince(e.target.value)}
                    defaultValue={selectedProvince.id}
                  >
                    {province.map((p) => (
                      <option
                        key={p.id}
                        value={p.name}
                        name={p.name}
                        selected={
                          isEdit
                            ? p.name == editobj[0].province
                              ? true
                              : false
                            : p.name == province[2].name
                            ? true
                            : false
                        }
                      >
                        {p.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Col sm={4} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Select District</Form.Label>
                    <Form.Select
                      id="district"
                      value={districtItems.id}
                      onChange={(e) => setSelectedDistrict(e.target.value)}
                      defaultValue={districtItems.id}
                    >
                      {districtItems.map((p) => (
                        <option
                          key={p.id}
                          value={p.name}
                          name={p.name}
                          selected={
                            isEdit
                              ? p.name == editobj[0].district
                                ? true
                                : false
                              : p.name == districtItems[5].name
                              ? true
                              : false
                          }
                        >
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={4} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Municipality</Form.Label>
                    <Form.Select
                      id="municipality"
                      onChange={(e) => setMunicipality(e.target.value)}
                    >
                      {municipalityItems.map((c) => (
                        <option
                          key={c.id}
                          value={c.name}
                          name={c.name}
                          selected={
                            isEdit
                              ? c.name == editobj[0].municipality
                                ? true
                                : false
                              : true
                          }
                        >
                          {c.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-2">
                    <Form.Label>Ward</Form.Label>
                    <Form.Select
                      id="ward"
                      onChange={(e) => setSelectedWard(e.target.value)}
                    >
                      {wards.map((c) => (
                        <option
                          key={c.id}
                          value={c.name}
                          name={c.name}
                          selected={
                            isEdit
                              ? c.name == editobj[0].ward
                                ? true
                                : false
                              : true
                          }
                        >
                          {c.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <h5 className="mb-4">Map Position</h5>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="longitude">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="28.3949"
                      name="longitude"
                      value={longitude}
                      onChange={(e) => {
                        setLongitude(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="latitude">
                    <Form.Label>Latitdue</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="84.1240"
                      name="latitude"
                      value={latitude}
                      onChange={(e) => {
                        setLatitude(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                <Button variant="primary" type="submit">
                  {isEdit ? "Edit" : "Save"}
                </Button>
              </div>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default HeritageWalkForm;
