import { http, httpFile } from "../http-common";

class CategoryService {
  //this is unused
  getAll() {
    return http.get("/sub-category");
  }

    get(id) {
      return http.get(`/sub-category/${id}`);
    }

  getByCategoryId(id) {
    return http.get(`/sub-category/category/${id}`);
  }

  create(data) {
    return http.post("/sub-category", data);
  }

  update(id, data) {
    return http.put(`/sub-category/${id}`, data);
  }

  delete(id) {
    return http.delete(`/sub-category/${id}`);
  }

  deleteAll() {
    return http.delete(`/sub-category`);
  }
}

export default new CategoryService();
