import {
  MAKE_REQ,
  CREATE_SUB_CATEGORY,
  RETRIEVE_SUB_CATEGORIES,
  REQ_GETALL_FAIL,
  UPDATE_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  REQ_GETBYID_SUCC,
} from "./types/subcategoryType";

export const makeRequest = () => {
  return {
    type: MAKE_REQ,
  };
};

export const getAllRequestSuccess = (data) => {
  return {
    type: RETRIEVE_SUB_CATEGORIES,
    payload: data,
  };
};

export const getAllRequestFail = (err) => {
  return {
    type: REQ_GETALL_FAIL,
    payload: err,
  };
};

export const AddRequest = (data) => {
  return {
    type: CREATE_SUB_CATEGORY,
    payload: data,
  };
};

export const UpdateRequest = (data) => {
  return {
    type: UPDATE_SUB_CATEGORY,
    payload: data,
  };
};

export const RemoveRequest = (id) => {
  return {
    type: DELETE_SUB_CATEGORY,
    payload: id,
  };
};

export const getbyIdSuccess = (data) => {
  return {
    type: REQ_GETBYID_SUCC,
    payload: data,
  };
};
