import { toast } from "react-toastify";

import {
  RemoveRequest,
  getAllRequestFail,
  getAllRequestSuccess,
  getbyIdSuccess,
  makeRequest,
} from "../contactUsAction";
import contactUsService from "../../services/contactUs.service";

export const GetAllContactUs = () => {
  return (dispatch) => {
    dispatch(makeRequest());
    setTimeout(() => {
      contactUsService
        .getAll()
        .then((res) => {
          const _list = res.data;
          dispatch(getAllRequestSuccess(_list));
        })
        .catch((err) => {
          dispatch(getAllRequestFail(err.message));
        });
    }, 1000);
  };
};

export const GetContactUsById = (id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    contactUsService
      .get(id)
      .then((res) => {
        const _obj = res.data;
        dispatch(getbyIdSuccess(_obj));
      })
      .catch((err) => {
        toast.error("Failed to fetch the data");
      });
  };
};

export const RemoveContactUs = (id) => {
  return (dispatch) => {
    contactUsService
      .delete(id)
      .then((res) => {
        // dispatch(RemoveRequest(id));
        dispatch(GetAllContactUs());
        toast.success("Contact message removed successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to remove contact message due to :" + message);
      });
  };
};
