import { toast } from "react-toastify";

import {
  AddRequest,
  RemoveRequest,
  UpdateRequest,
  getAllRequestFail,
  getAllRequestSuccess,
  getbyIdSuccess,
  makeRequest,
} from "../discoverAction";
import discoveryService from "../../services/discovery.service";

export const GetAllCategories = () => {
  return (dispatch) => {
    dispatch(makeRequest());
    setTimeout(() => {
      discoveryService
        .getAll()
        .then((res) => {
          const _list = res.data;
          dispatch(getAllRequestSuccess(_list));
        })
        .catch((err) => {
          dispatch(getAllRequestFail(err.message));
        });
    }, 1000);
  };
};

export const GetDiscoverById = (id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    discoveryService
      .get(id)
      .then((res) => {
        const _obj = res.data;
        dispatch(getbyIdSuccess(_obj));
      })
      .catch((err) => {
        toast.error("Failed to fetch the data");
      });
  };
};

export const GetDiscoverBySubCategoryId = (id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    discoveryService
      .getBySubCategoryId(id)
      .then((res) => {
        const _obj = res.data;
        dispatch(getAllRequestSuccess(_obj));
      })
      .catch((err) => {
        toast.error("Failed to fetch the data");
      });
  };
};

export const CreateDiscover = (data) => {
  return (dispatch) => {
    discoveryService
      .create(data)
      .then((res) => {
        dispatch(AddRequest(data));

        toast.success("Discover place created successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to create discover place due to :" + message);
      });
  };
};

export const UpdateDiscover = (data,id) => {
  return (dispatch) => {
    discoveryService
      .update(id, data)
      .then((res) => {
        dispatch(UpdateRequest(data));
        toast.success("Discover place updated successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to update discover place due to :" + message);
      });
  };
};

export const RemoveDiscover = (id, imageLink) => {
  return (dispatch) => {
    discoveryService
      .delete(id, imageLink)
      .then((res) => {
        dispatch(RemoveRequest(id));
        toast.success("Discover place removed successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to remove discover place due to :" + message);
      });
  };
};
