import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faCartArrowDown,
  faChartPie,
  faChevronDown,
  faClipboard,
  faCommentDots,
  faFileAlt,
  faHome,
  faPlus,
  faRocket,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Table,
  Image,
  Card,
  Breadcrumb,
} from "@themesberg/react-bootstrap";
import {
  ChoosePhotoWidget,
  PreviewContactUsWidget,
  ProfileCardWidget,
} from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";

import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { CommandsTable, ContactUsTable, FaqsTable } from "../components/Tables";
import { Link } from "react-router-dom";
import { RoutesCustom } from "../routescustom";
import { connect, useSelector } from "react-redux";
import {
  GetAllContactUs,
  GetContactUsById,
} from "../actions/actionCreaters/contactUsActionCreater";

const ContactUs = (props) => {
  useEffect(() => {
    props.loadcontactUs();
  }, []);

  return (
    // props.contactUsState.isloading ? (
    //   <div>
    //     <h2>Loading.....</h2>
    //   </div>
    // ) : props.contactUsState.errormessage ? (
    //   <div>
    //     <h2>{props.contactUsState.errormessage}</h2>
    //   </div>
    // ) :
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <Link to={RoutesCustom.DashboardOverview.path}>
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Contact us</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Contact Us</h4>
          <p className="mb-0">See the messages</p>
        </div>
      </div>
      <Row className="mt-5">
        <Col xs={12} xl={12}>
          <ContactUsTable contactList={props.contactUsState.contactList} />
        </Col>
      </Row>
    </>
  );
};

const mapDispatchtoProps = (dispatch) => {
  return {
    loadcontactUs: () => dispatch(GetAllContactUs()),
  };
};
const mapStatetoProps = (state) => {
  return {
    contactUsState: state.contactUs,
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(ContactUs);
