import React, { useState, useEffect } from "react";

import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { CreateFAQ, UpdateFAQ } from "../../actions/actionCreaters/faqActionCreater";

const FAQsForm = ({ isedit, closepopup }) => {
  const dispatch = useDispatch();

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const editobj = useSelector((state) => state.faq.faqObj);

  const handlesubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("question", question);
    formData.append("answer", answer);
    if (isedit != false) {
        // formData.append("faq_id", editobj[0].faq_id)
        dispatch(UpdateFAQ(formData,editobj[0].faq_id));
      } else {
        dispatch(CreateFAQ(formData));
      }
    closepopup();
  };


  useEffect(() => {
    if (Object.keys(editobj).length > 0 && isedit) {
     
      setQuestion(editobj[0].question);
      setAnswer(editobj[0].answer);
     
    } else {
      // clearstate();
    }
  }, [editobj]);
  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4 w-100">
        <Card.Body>
          {/* <h5 className="mb-4">FAQ</h5> */}
          <Form onSubmit={handlesubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="question">
                  <Form.Label>Question</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter question"
                    name="question"
                    value={question}
                    onChange={(e) => setQuestion(e.currentTarget.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Group id="answer">
                  <Form.Label>Answer</Form.Label>
                 
                    <textarea class="form-control"  rows="3" required
                    type="text"
                    placeholder="Enter your answer"
                    name="answer"
                    value={answer}
                    onChange={(e) => setAnswer(e.currentTarget.value)}></textarea>

                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3">
              <Button variant="primary" type="submit">
                {isedit ? "Edit" : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default FAQsForm;
