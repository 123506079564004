import {
  MAKE_REQ,
  RETRIEVE_DASHBOARD,
  REQ_GETALL_SUCC,
  REQ_GETALL_FAIL,
} from "../actions/types/dashboardType";

const initialState = {
  isloading: false,
  dashboardList: [],
  dashboardObj: {},
  errormessage: "",
};

function dashboardReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MAKE_REQ:
      return {
        ...state,
        isloading: true,
      };

    case REQ_GETALL_SUCC:
      return {
        ...state,
        isloading: false,
        dashboardList: payload,
      };

    case REQ_GETALL_FAIL:
      return {
        ...state,
        isloading: false,
        companylist: [],
        errormessage: payload,
      };

    case RETRIEVE_DASHBOARD:
      return {
        ...state,
        isloading: false,
        dashboardList: payload,
      };

    default:
      return state;
  }
}

export default dashboardReducer;
