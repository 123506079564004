import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faCartArrowDown,
  faChartPie,
  faChevronDown,
  faClipboard,
  faCommentDots,
  faFileAlt,
  faHome,
  faPlus,
  faRocket,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Table,
  Image,
  Card,
  Breadcrumb,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import {
  ChoosePhotoWidget,
  PreviewContactUsWidget,
  ProfileCardWidget,
} from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";

import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { RoutesCustom } from "../routescustom";
import { Link } from "react-router-dom";
import FAQsForm from "./forms/FaqsForm";
import { Modal } from "@themesberg/react-bootstrap";
import { FaqsTable, SubCategoriesTable } from "../components/Tables";
import { GetAllFAQs, GetFAQById } from "../actions/actionCreaters/faqActionCreater";
import { connect, useDispatch } from "react-redux";

const Faqs = (props) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const closepopup = () => {
    setShowDefault(false);
  };
  const [isedit, iseditchange] = useState(false);
  const [title, titlechange] = useState("Create FAQ");

  const dispatch = useDispatch();

  const handleEdit = (id) => {
    iseditchange(true);
    titlechange("Update FAQ");
    setShowDefault(true);
    dispatch(GetFAQById(id));
  };

  useEffect(() => {
    props.loadfaqs();
  }, []);

  return (props.faqState.isloading ? (
    <div>
      <h2>Loading.....</h2>
    </div>
  ) : props.faqState.errormessage ? (
    <div>
      <h2>{props.faqState.errormessage}</h2>
    </div>
  ) : 
    <>
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">{title}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <FAQsForm isedit={isedit} closepopup={closepopup} />
        </Modal.Body>
      </Modal>
      
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <Link to={RoutesCustom.DashboardOverview.path}>
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Faqs
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>FAQs</h4>
          <p className="mb-0">Add frequently asked question.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button
              variant="outline-primary"
              size="sm"
                onClick={() => setShowDefault(true)}
            >
              Add New
            </Button>
            {/* <Button variant="outline-primary" size="sm">
              Export
            </Button> */}
          </ButtonGroup>
        </div>
      </div>
      <FaqsTable  handleEdit={handleEdit}/>

    </>
  );
};

const mapDispatchtoProps = (dispatch) => {
    return {
      loadfaqs: () => dispatch(GetAllFAQs()),
    };
  };
  const mapStatetoProps = (state) => {
    return {
       
        faqState: state.faq,
    };
  };
export default connect(mapStatetoProps, mapDispatchtoProps)(Faqs);
