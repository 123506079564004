import {
    MAKE_REQ,
    CREATE_HERITAGE_WALK,
    RETRIEVE_HERITAGE_WALK,
    UPDATE_HERITAGE_WALK,
    DELETE_HERITAGE_WALK,
    REQ_GETALL_SUCC,
    REQ_GETALL_FAIL,
    REQ_GETBYID_SUCC,
  } from "../actions/types/heritageWalksType";
  
  const initialState = {
    isloading: false,
    heritageWalkList: [],
    heritageWalkObj: {},
    errormessage: "",
  };
  
  function heritageWalkReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case MAKE_REQ:
        return {
          ...state,
          isloading: true,
        };
      case CREATE_HERITAGE_WALK:
        const _inputdata = { ...payload };
       
        return {
          ...state,
          heritageWalkList: [...state.heritageWalkList, _inputdata],
        };
      case REQ_GETBYID_SUCC:
        return {
          ...state,
          isloading: false,
          heritageWalkObj: payload,
        };
      case REQ_GETALL_SUCC:
        return {
          ...state,
          isloading: false,
          heritageWalkList: payload,
        };
  
      case REQ_GETALL_FAIL:
        return {
          ...state,
          isloading: false,
          companylist: [],
          errormessage: payload,
        };
  
      case RETRIEVE_HERITAGE_WALK:
        return {
          ...state,
          isloading: false,
          heritageWalkList: payload,
        };
  
      case UPDATE_HERITAGE_WALK:
        const _data = { ...action.payload };
        const _finaldata = state.heritageWalkList.map((item) => {
          return item.id === _data.id ? _data : item;
        });
        return {
          ...state,
          heritageWalkList: _finaldata,
        };
  
      case DELETE_HERITAGE_WALK:
        const _filterdata = state.heritageWalkList.filter((data) => {
          return data.category_id !== payload;
        });
        return {
          ...state,
          heritageWalkList: _filterdata,
        };
  
   
      default:
        return state;
    }
  }
  
  export default heritageWalkReducer;
  