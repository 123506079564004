import { http, httpFile } from "../http-common";

class DiscoveryService {
  getAll() {
    return http.get("/discover");
  }

  get(id) {
    return http.get(`/discover/${id}`);
  }

  getBySubCategoryId(id) {
    return http.get(`/discover/sub_category/${id}`);
  }

  create(data) {
    return httpFile.post("/discover", data);
  }

  update(id, data) {
    return httpFile.put(`/discover/${id}`, data);
  }

  delete(id) {
    return httpFile.delete(`/discover/${id}`);
  }

  deleteAll() {
    return http.delete(`/discover`);
  }
}

export default new DiscoveryService();
