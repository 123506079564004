import { http, httpFile } from "../http-common";

class FaqService {
  getAll() {
    return http.get("/faqs");
  }

  get(id) {
    return http.get(`/faqs/${id}`);
  }

  create(data) {
    return http.post("/faqs", data);
  }

  update(id, data) {
    return http.put(`/faqs/${id}`, data);
  }

  delete(id,) {
    return http.delete(`/faqs/${id}`);
  }

  deleteAll() {
    return http.delete(`/faqs`);
  }

 
}

export default new FaqService();
