import {
  MAKE_REQ,
  RETRIEVE_DASHBOARD,
  REQ_GETALL_FAIL,
} from "./types/dashboardType";

export const makeRequest = () => {
  return {
    type: MAKE_REQ,
  };
};

export const getAllRequestSuccess = (data) => {
  return {
    type: RETRIEVE_DASHBOARD,
    payload: data,
  };
};

export const getAllRequestFail = (err) => {
  return {
    type: REQ_GETALL_FAIL,
    payload: err,
  };
};
