import { toast } from "react-toastify";
import {
  AddRequest,
  RemoveRequest,
  UpdateRequest,
  getAllRequestFail,
  getAllRequestSuccess,
  getbyIdSuccess,
  makeRequest,
} from "../subcategoryAction";
import subcategoriesService from "../../services/subcategories.service";

export const GetAllSubCategories = (id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    setTimeout(() => {
      subcategoriesService
        .getByCategoryId(id)
        .then((res) => {
          const _list = res.data;
          dispatch(getAllRequestSuccess(_list));
        })
        .catch((err) => {const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
          dispatch(getAllRequestFail(message));
        });
    }, 1000);
  };
};

export const GetSubCategoryById = (id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    subcategoriesService
      .get(id)

      .then((res) => {
        const _obj = res.data;
        dispatch(getbyIdSuccess(_obj));
      })
      .catch((err) => {
        toast.error("Failed to fetch the data");
      });
  };
};

// export const GetSubCategoryByCategoryId = (id) => {
//   return (dispatch) => {
//     dispatch(makeRequest());
//     subcategoriesService
//       .getByCategoryId(id)
//       .then((res) => {
//         const _obj = res.data;
//         dispatch(getbyIdSuccess(_obj));
//       })
//       .catch((err) => {
//         toast.error("Failed to fetch the data");
//       });
//   };
// };

export const CreateSubCategory = (data) => {
  return (dispatch) => {
    subcategoriesService
      .create(data)
      .then((res) => {
        dispatch(AddRequest(res.data));
        toast.success("Sub Category created successfully.");
      }).catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to create sub category due to :" + message);
      });
     
  };
};

export const UpdateSubCategory = (data,id) => {
  return (dispatch) => {
    subcategoriesService
      .update(id, data)
      .then((res) => {
        dispatch(UpdateRequest(res.data));
        toast.success("Sub category updated successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to update sub category due to :" + message);
      });
  };
};

export const RemoveSubCategory = (id) => {
  return (dispatch) => {
    subcategoriesService
      .delete(id)
      .then((res) => {
        dispatch(RemoveRequest(id));
        toast.success("Sub category removed successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to remove sub category due to :" + message);
      });
  };
};
