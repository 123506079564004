import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { RoutesCustom } from "../routescustom";

// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./auth/Signin";
import Signup from "./auth/Signup";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import Lock from "./auth/Lock";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import Categories from "./Categories";
import SubCategories from "./SubCategories";
import SubCategoriesView from "./SubCategoryView";
import ContactUs from "./ContactUs";
import HeritageWalk from "./HeritageWalk";
import Maps from "./Maps";
import ClimateChange from "./ClimateChange";
import Faqs from "./Faqs";
import HeritageWalkSteps from "./HeritageWalkSteps";

const RouteWithLoader = ({ components: Components, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={loaded ? false : true} /> <Components />
    </>
    // <Route
    //   {...rest}
    //   element={(props) => (
    //     <>
    //       {" "}
    //       <Preloader show={loaded ? false : true} /> <Components {...props} />{" "}
    //     </>
    //   )}
    // />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <>
      {/* <Preloader show={loaded ? false : true} /> */}
      <Sidebar />

      <main className="content">
        <Navbar />
        <Component />
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
    // <Routes>
    //   <Route
    //     {...rest}
    //     element={(props) => (
    //       <>
    //         <Preloader show={loaded ? false : true} />
    //         <Sidebar />

    //         <main className="content">
    //           <Navbar />
    //           <Component {...props} />
    //           {/* <Footer
    //           toggleSettings={toggleSettings}
    //           showSettings={showSettings}
    //         /> */}
    //         </main>
    //       </>
    //     )}
    //   />
    // </Routes>
  );
};

export default () => (
  <Routes>
    {/* <RouteWithLoader
      
      path={Routes.Presentation.path}
      component={Presentation}
    /> */}
    <Route
      path={RoutesCustom.Presentation.path}
      element={<RouteWithLoader components={Signin} />}
    />

    {/* pages */}
    <Route
      path={RoutesCustom.DashboardOverview.path}
      element={<RouteWithSidebar component={DashboardOverview} />}
    />
    <Route
      path={RoutesCustom.categories.path}
      element={<RouteWithSidebar component={Categories} />}
    />
    <Route
      path={RoutesCustom.SubCategories.path}
      element={<RouteWithSidebar component={SubCategories} />}
    />
    <Route
      path={RoutesCustom.SubCategoriesView.path}
      element={<RouteWithSidebar component={SubCategoriesView} />}
    />
    <Route
      path={RoutesCustom.heritageWalk.path}
      element={<RouteWithSidebar component={HeritageWalk} />}
    />

    <Route
      path={RoutesCustom.heritageWalkSteps.path}
      element={<RouteWithSidebar component={HeritageWalkSteps} />}
    />

    <Route
      path={RoutesCustom.maps.path}
      element={<RouteWithSidebar component={Maps} />}
    />

    <Route
      path={RoutesCustom.climateChange.path}
      element={<RouteWithSidebar component={ClimateChange} />}
    />

    <Route
      path={RoutesCustom.contactUs.path}
      element={<RouteWithSidebar component={ContactUs} />}
    />
    <Route
      path={RoutesCustom.faqs.path}
      element={<RouteWithSidebar component={Faqs} />}
    />

    {/* <Route
        path={RoutesCustom.DashboardOverview.path}
        element={DashboardOverview}
      /> */}

    {/* <RouteWithLoader path={RoutesCustom.Presentation.path} component={Signin} />
    <RouteWithLoader path={RoutesCustom.Signup.path} component={Signup} />
    <RouteWithLoader
      path={RoutesCustom.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      path={RoutesCustom.ResetPassword.path}
      component={ResetPassword}
    />
    <RouteWithLoader path={RoutesCustom.Lock.path} component={Lock} />
    <RouteWithLoader
      path={RoutesCustom.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      path={RoutesCustom.ServerError.path}
      component={ServerError}
    /> */}

    {/* pages */}
    {/* <RouteWithSidebar
      path={RoutesCustom.DashboardOverview.path}
      component={DashboardOverview}
    />
    <RouteWithSidebar path={RoutesCustom.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar
      path={RoutesCustom.Transactions.path}
      component={Transactions}
    />
    <RouteWithSidebar
      path={RoutesCustom.SubCategories.path}
      component={SubCategories}
    />
    <RouteWithSidebar
      path={RoutesCustom.SubCategoriesView.path}
      component={SubCategoriesView}
    />
    <RouteWithSidebar path={RoutesCustom.Settings.path} component={Settings} />
    <RouteWithSidebar
      path={RoutesCustom.categories.path}
      component={Categories}
    />
    <RouteWithSidebar
      path={RoutesCustom.BootstrapTables.path}
      component={BootstrapTables}
    /> */}

    {/* components */}
    {/* <RouteWithSidebar
      path={RoutesCustom.Accordions.path}
      component={Accordion}
    />
    <RouteWithSidebar path={RoutesCustom.Alerts.path} component={Alerts} />
    <RouteWithSidebar path={RoutesCustom.Badges.path} component={Badges} />
    <RouteWithSidebar
      path={RoutesCustom.Breadcrumbs.path}
      component={Breadcrumbs}
    />
    <RouteWithSidebar path={RoutesCustom.Buttons.path} component={Buttons} />
    <RouteWithSidebar path={RoutesCustom.Forms.path} component={Forms} />
    <RouteWithSidebar path={RoutesCustom.Modals.path} component={Modals} />
    <RouteWithSidebar path={RoutesCustom.Navs.path} component={Navs} />
    <RouteWithSidebar path={RoutesCustom.Navbars.path} component={Navbars} />
    <RouteWithSidebar
      path={RoutesCustom.Pagination.path}
      component={Pagination}
    />
    <RouteWithSidebar path={RoutesCustom.Popovers.path} component={Popovers} />
    <RouteWithSidebar path={RoutesCustom.Progress.path} component={Progress} />
    <RouteWithSidebar path={RoutesCustom.Tables.path} component={Tables} />
    <RouteWithSidebar path={RoutesCustom.Tabs.path} component={Tabs} />
    <RouteWithSidebar path={RoutesCustom.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar path={RoutesCustom.Toasts.path} component={Toasts} /> */}

    {/* documentation */}
    {/* <RouteWithSidebar
      path={RoutesCustom.DocsOverview.path}
      component={DocsOverview}
    />
    <RouteWithSidebar
      path={RoutesCustom.DocsDownload.path}
      component={DocsDownload}
    />
    <RouteWithSidebar
      path={RoutesCustom.DocsQuickStart.path}
      component={DocsQuickStart}
    />
    <RouteWithSidebar
      path={RoutesCustom.DocsLicense.path}
      component={DocsLicense}
    />
    <RouteWithSidebar
      path={RoutesCustom.DocsFolderStructure.path}
      component={DocsFolderStructure}
    />
    <RouteWithSidebar
      path={RoutesCustom.DocsBuild.path}
      component={DocsBuild}
    />
    <RouteWithSidebar
      path={RoutesCustom.DocsChangelog.path}
      component={DocsChangelog}
    /> */}

    {/* <Navigate to={RoutesCustom.NotFound.path} /> */}
  </Routes>
);
