import { http, httpFile } from "../http-common";

class HeritageWalkService {
  getAll() {
    return http.get("/heritage-walk");
  }

  get(id) {
    return http.get(`/heritage-walk/${id}`);
  }

  create(data) {
    return httpFile.post("/heritage-walk", data);
  }

  update(id, data) {
    return httpFile.put(`/heritage-walk/${id}`, data);
  }

  delete(id,) {
    return http.delete(`/heritage-walk/${id}`);
  }

  deleteAll() {
    return http.delete(`/heritage-walk`);
  }

 
}

export default new HeritageWalkService();
