export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const RETRIEVE_CATEGORIES = "RETRIEVE_CATEGORIES";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_ALL_CATEGORIES = "DELETE_ALL_CATEGORIES";
export const REQ_GETBYID_SUCC = "REQ_GETBYID_SUCC";

export const MAKE_REQ = "MAKE_REQ";
export const REQ_GETALL_SUCC = "REQ_GETALL_SUCC";
export const REQ_GETALL_FAIL = "REQ_GETALL_FAIL";

export const OPEN_POPUP = "OPEN_POPUP";
