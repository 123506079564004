export const CREATE_DISCOVER = "CREATE_DISCOVER";
export const RETRIEVE_DISCOVER = "RETRIEVE_DISCOVER";
export const UPDATE_DISCOVER = "UPDATE_DISCOVER";
export const DELETE_DISCOVER = "DELETE_DISCOVER";
export const DELETE_ALL_DISCOVER = "DELETE_ALL_DISCOVER";
export const REQ_GETBYID_SUCC = "REQ_GETBYID_SUCC";

export const MAKE_REQ = "MAKE_REQ";
export const REQ_GETALL_SUCC = "REQ_GETALL_SUCC";
export const REQ_GETALL_FAIL = "REQ_GETALL_FAIL";
