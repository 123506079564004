import React, { useEffect, useState } from "react";
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";
import { Col, Row, Button, Dropdown, Breadcrumb } from "@themesberg/react-bootstrap";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { Modal } from "@themesberg/react-bootstrap";
import CategoryForm from "./forms/CategoryForm";
import { Link, useLocation } from "react-router-dom";
import { Routes, RoutesCustom } from "../routescustom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  GetDiscoverById,
  GetDiscoverBySubCategoryId,
  RemoveDiscover,
} from "../actions/actionCreaters/discoverActionCreater";
import { imgBaseUrl } from "../services/api";
import DiscoverPlacesForm from "./forms/DiscoverPlacesForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const SubCategoriesView = (props) => {
  const [showDefault, setShowDefault] = useState(false);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setShowEditDefault(false);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const dispatch = useDispatch();

  const location = useLocation();

  const [isedit, iseditchange] = useState(false);
  const [title, titlechange] = useState("Create New Place");
  //preparing for deleting
  const [id, setId] = useState(null);

  useEffect(() => {
    // alert(location.state.sub_category_id)
    // dispatch(GetDiscoverBySubCategoryId(location.state.sub_category_id));
    props.loadSubCategoriesView(location.state.sub_category_id);
  }, []);

  const previewObj = useSelector((state) => state.discover.discoverObj);

  const handleEdit = (id) => {
    iseditchange(true);
    titlechange("Update Place");
    setShowEditDefault(true);
    dispatch(GetDiscoverById(id));
  };

  const handleRemove = () => {
    // alert(id);
    dispatch(RemoveDiscover(id));
    handleCloseDelete();
  };

  const handlePreview = (id) => {
    setShowDefault(true);
    dispatch(GetDiscoverById(id));
  };


  useEffect(() => {
    if (Object.keys(previewObj).length > 0) {
    
      // setDiscoverFormData({
      //   title: previewObj[0].title,
      //   fullAddress: previewObj[0].fullAddress,
      //   description: previewObj[0].description,
      //   reference: previewObj[0].reference,
      //   longitude: previewObj[0].longitude,
      //   latitude: previewObj[0].latitude,
      // });
    } else {
      // clearstate();
    }
  }, [previewObj]);

  return (
    <>
      {/* edit form  */}
      <Modal
        as={Modal.Dialog}
        centered
        show={showEditDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">{title}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <DiscoverPlacesForm
            isedit={isedit}
            subCatId={location.state.sub_category_id}
          />
        </Modal.Body>
      </Modal>
      {/* preview */}
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Preview</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {previewObj != {} && (
            <div className="site__page page">
              <section
                class="heritage-container container article-story-group mt-1"
                data-stories="8"
              >
                <div class="article-story-group__items mt-5">
                  <div class="article-story-group__item">
                    <article
                      data-article-id="97758"
                      about="/news/elneny-warms-afcon-win"
                      class="node node--type-article node--view-mode-full"
                    >
                      <div class="article-card-header card rounded shadow">
                        <div class="card__content">
                          <div class="article-card-header__wrapper">
                            <div class="article-card-header__content">
                              <span class="h6 article-card-header__subhead">
                                INFO HERITAGE
                              </span>
                              <h1
                                class="article-card-header__title"
                                title="Rato Machhindranath Temple"
                              >
                                <span class="article-card-header__title-words">
                                  {previewObj[0].title}
                                </span>
                              </h1>
                              <div class="article-card-header__metadata">
                                <span class="article-card-header__author">
                                  <a href="#"> - {previewObj[0].posted_by}</a>
                                </span>
                                <span class="article-card-header__date">
                                  {previewObj[0].createdAt}
                                </span>

                                <div className="pull-right">
                                  <span class="article-card-header__author">
                                    <i className="fa fa-map-marker"></i>
                                    &nbsp; {previewObj[0].fullAddress}
                                  </span>
                                  <span class="article-card-header__date">
                                    &nbsp;&nbsp;{" "}
                                    <span>
                                      {previewObj[0].visit_count} views
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="article-card-header__media">
                              <picture></picture>
                              <img
                                src={
                                  imgBaseUrl + previewObj[0].image_file_name ??
                                  "https://ktmguide.com/wp-content/uploads/2020/03/r0-1.jpg"
                                }
                                width={1240}
                                className=" img-fluid"
                              ></img>
                            </div>
                          </div>
                        </div>

                        <div class="node__content ">
                          <div class="article-body">
                            {/* {previewObj[0].description} */}
                            <div dangerouslySetInnerHTML={{ __html: previewObj[0].description }} />
                            {/* {discoverFormData.description} */}
                          </div>

                          <div>
                            <p class="references">References</p>
                            <p>{previewObj[0].reference}</p>
                          </div>
                        </div>
                      </div>

                      <div class="article-card-header card rounded shadow">
                        <div class="card__content">
                          <div class="article-card-header__wrapper node__content article-body">
                            <div class="article-card-header__content">
                              <h1 class="article-card-header__title" title="">
                                <span class="article-card-header__title-words">
                                  {" "}
                                  Place Location
                                </span>
                              </h1>
                              <div class="article-card-header__metadata">
                                <div className="">
                                  <span class="article-card-header__author">
                                    <i className="fa fa-map-marker"></i>
                                    &nbsp;{previewObj[0].fullAddress}
                                  </span>
                                  <span class="article-card-header__date">
                                    &nbsp;&nbsp;{" "}
                                    <span>
                                      {previewObj[0].visit_count} views
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="article-card-header__media"></div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </section>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Header>
          <Modal.Title className="h6">Are your sure ?</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>Once delete it will not be able to recover.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" aria-label="Yes" onClick={handleRemove}>
            Yes
          </Button>
          <Button
            variant="outline-primary"
            aria-label="No"
            onClick={handleCloseDelete}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <Link to={RoutesCustom.DashboardOverview.path}>
                {" "}
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {" "}
              <Link to={RoutesCustom.categories.path}>Discover</Link>
            </Breadcrumb.Item>
            {/* <Breadcrumb.Item active><Link to={RoutesCustom.SubCategories.path}>SubCategories</Link></Breadcrumb.Item> */}
            <Breadcrumb.Item active>SubCategoriesView</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Discover Places</h4>
          {/* <p className="mb-0"> add discover places.</p> */}
        </div></div>
    
      <div className="row">
        {props.discoverState.discoverList &&
          props.discoverState.discoverList.map((row, i) => {
            return (
              <div class="col-md-4 mb-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src={imgBaseUrl + row.image_file_name}
                    alt=""
                    height={270}
                  />
                  <div class="card-body">
                    <p class="h6">
                      <small class="text-muted">Name</small>
                      <br />
                      {row.title}
                    </p>
                  </div>
                  <div class="card-footer p-0">
                    <div class="btn-group d-flex" role="group">
                      <button
                        type="button"
                        class="btn btn-light bg-info text-white"
                        onClick={() => handleEdit(row.discover_id)}
                      >
                        <i class="fas fa-edit"></i>
                        <span>Edit</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-light bg-danger text-white"
                        onClick={() => {
                          setShowDelete(true);
                          setId(row.discover_id);
                        }}
                      >
                        <i class="fas fa-trash"></i>
                        <span>Delete</span>
                      </button>
                      {/* <button
                  type="button"
                  class="btn btn-light bg-primary text-white"
                >
                  <i class="fas fa-plus"></i>
                  <span>Add Sub</span>
                </button> */}

                      <button
                        type="button"
                        class="btn btn-light bg-primary text-white"
                        onClick={() => handlePreview(row.discover_id)}
                      >
                        <i class="fas fa-eye"></i>
                        <span>Preview</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
const mapDispatchtoProps = (dispatch) => {
  return {
    loadSubCategoriesView: (sub_category_id) =>
      dispatch(GetDiscoverBySubCategoryId(sub_category_id)),
  };
};
const mapStatetoProps = (state) => {
  return {
    discoverState: state.discover,
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(SubCategoriesView);
