export const CREATE_FAQ = "CREATE_FAQ";
export const RETRIEVE_FAQS = "RETRIEVE_FAQS";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const DELETE_ALL_FAQS = "DELETE_ALL_FAQS";
export const REQ_GETBYID_SUCC = "REQ_GETBYID_SUCC";

export const MAKE_REQ = "MAKE_REQ";
export const REQ_GETALL_SUCC = "REQ_GETALL_SUCC";
export const REQ_GETALL_FAIL = "REQ_GETALL_FAIL";


