import { toast } from "react-toastify";

import {
  AddRequest,
  RemoveRequest,
  UpdateRequest,
  getAllRequestFail,
  getAllRequestSuccess,
  getbyIdSuccess,
  makeRequest,
} from "../categoriesAction";
import categoryService from "../../services/category.service";

export const GetAllCategories = () => {
  return (dispatch) => {
    dispatch(makeRequest());
    setTimeout(() => {
      categoryService
        .getAll()
        .then((res) => {
          const _list = res.data;
          dispatch(getAllRequestSuccess(_list));
        })
        .catch((err) => {
          dispatch(getAllRequestFail(err.message));
        });
    }, 1000);
  };
};

export const GetCategoryById = (id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    categoryService
      .get(id)

      .then((res) => {
        const _obj = res.data;
        dispatch(getbyIdSuccess(_obj));
      })
      .catch((err) => {
        toast.error("Failed to fetch the data");
      });
  };
};

export const CreateCategory = (data) => {
  return (dispatch) => {
    categoryService
      .create(data)
      .then(
        (res) => {
          // dispatch(AddRequest(data));

          dispatch(GetAllCategories());
          toast.success("Category created successfully.");
        }
        // (err) => {
        //   const message =
        //     (err.response && err.response.data && err.response.data.msg) ||
        //     err.msg ||
        //     err.toString();
        //   toast.error("Failed to create category due to :" + message);
        // }
      )
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to create category due to :" + message);
      });
  };
};

export const UpdateCategory = (data) => {
  return (dispatch) => {
    categoryService
      .update(data.category_id, data)
      .then((res) => {
        dispatch(UpdateRequest(data));
        toast.success("Category updated successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to update category due to :" + message);
      });
  };
};

export const RemoveCategory = (id, imageLink) => {
  return (dispatch) => {
    categoryService
      .delete(id, imageLink)
      .then((res) => {
        dispatch(RemoveRequest(id));
        toast.success("Category removed successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to remove category due to :" + message);
      });
  };
};
