import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import categoryReducer from "./categories";
import subCategoryReducer from "./subcategories";
import discoverReducer from "./discoverPlaces";
import faqReducer from "./faqs";
import contactUsReducer from "./contactUs";
import heritageWalkReducer from "./heritageWalk";
import heritageWalkStepsReducer from "./heritageWalkSteps";
import dashboardReducer from "./dashboard";

export default combineReducers({
  auth,
  message,
  category: categoryReducer,
  subCategory: subCategoryReducer,
  discover: discoverReducer,
  faq: faqReducer,
  contactUs: contactUsReducer,
  heritageWalk: heritageWalkReducer,
  heritageWalkSteps: heritageWalkStepsReducer,
  dashboard: dashboardReducer,
});
