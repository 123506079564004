export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
export const RETRIEVE_SUB_CATEGORIES = "RETRIEVE_SUB_CATEGORIES";
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";
export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY";
export const DELETE_ALL_SUB_CATEGORIES = "DELETE_ALL_SUB_CATEGORIES";
export const REQ_GETBYID_SUCC = "REQ_GETBYID_SUCC";

export const MAKE_REQ = "MAKE_REQ";
export const REQ_GETALL_SUCC = "REQ_GETALL_SUCC";
export const REQ_GETALL_FAIL = "REQ_GETALL_FAIL";
