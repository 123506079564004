import { toast } from "react-toastify";

import {
  AddRequest,
  RemoveRequest,
  UpdateRequest,
  getAllRequestFail,
  getAllRequestSuccess,
  getbyIdSuccess,
  makeRequest,
} from "../heritageWalkAction";

import heritagewalkService from "../../services/heritagewalk.service";

export const GetAllHeritageWalks = () => {
  return (dispatch) => {
    dispatch(makeRequest());
    setTimeout(() => {
      heritagewalkService
        .getAll()
        .then((res) => {
          const _list = res.data;
          dispatch(getAllRequestSuccess(_list));
        })
        .catch((err) => {
          dispatch(getAllRequestFail(err.message));
        });
    }, 1000);
  };
};

export const GetHeritageWalksById = (id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    heritagewalkService
      .get(id)
      .then((res) => {
        const _obj = res.data;
        dispatch(getbyIdSuccess(_obj));
      })
      .catch((err) => {
        toast.error("Failed to fetch the data");
      });
  };
};

export const CreateHeritageWalk = (data) => {
  return (dispatch) => {
    heritagewalkService
      .create(data)
      .then((res) => {
        // dispatch(AddRequest(data));

        dispatch(GetAllHeritageWalks());
        toast.success("Heritage walk place created successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to create heritage walk place due to :" + message);
      });
  };
};

export const UpdateHeritageWalk = (data, id) => {
  return (dispatch) => {
    heritagewalkService
      .update(id, data)
      .then((res) => {
        // dispatch(UpdateRequest(res.data));
        dispatch(GetAllHeritageWalks());
        toast.success("Heritage walk place updated successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to update heritage walk place due to :" + message);
      });
  };
};

export const RemoveHeritageWalk = (id, imageLink) => {
  return (dispatch) => {
    heritagewalkService
      .delete(id, imageLink)
      .then((res) => {
        // dispatch(RemoveRequest(id));
        dispatch(GetAllHeritageWalks());
        toast.success("Heritage walk place removed successfully.");
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data && err.response.data.msg) ||
          err.msg ||
          err.toString();
        toast.error("Failed to remove heritage walk place due to :" + message);
      });
  };
};
