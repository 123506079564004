import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, BrowserRouter  as Router} from "react-router-dom";

// core styles
import "./scss/volt.scss";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

// vendor styles
import "react-datetime/css/react-datetime.css";
import store from "./store";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="407712391583-26hc3mnekkrpv3lsup5kp2ul6vjjouhf.apps.googleusercontent.com">
      <Router>
        <ScrollToTop />
        <HomePage />
      </Router>
      <ToastContainer />
    </GoogleOAuthProvider>
  </Provider>
);
