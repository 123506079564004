import {
  MAKE_REQ,
  CREATE_SUB_CATEGORY,
  RETRIEVE_SUB_CATEGORIES,
  UPDATE_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  DELETE_ALL_SUB_CATEGORIES,
  REQ_GETALL_SUCC,
  REQ_GETALL_FAIL,
  REQ_GETBYID_SUCC,
} from "../actions/types/subcategoryType";

const initialState = {
  isloading: false,
  subcategoryList: [],
  subCategoryObj: {},
  errormessage: "",
};

function subCategoryReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MAKE_REQ:
      return {
        ...state,
        isloading: true,
      };
    case CREATE_SUB_CATEGORY:
      const _inputdata = { ...payload };
      return {
        ...state,
        subcategoryList: [...state.subcategoryList, _inputdata],
      };
    case REQ_GETBYID_SUCC:
      return {
        ...state,
        isloading: false,
        subCategoryObj: payload,
      };
    case REQ_GETALL_SUCC:
      return {
        ...state,
        isloading: false,
        subcategoryList: payload,
      };

    case REQ_GETALL_FAIL:
      return {
        ...state,
        isloading: false,
        companylist: [],
        errormessage: payload,
      };

    case RETRIEVE_SUB_CATEGORIES:
      return {
        ...state,
        isloading: false,
        subcategoryList: payload,
      };

    case UPDATE_SUB_CATEGORY:
      const _data = { ...action.payload };
      const _finaldata = state.subcategoryList.map((item) => {
        return item.id === _data.id ? _data : item;
      });
      return {
        ...state,
        subcategoryList: _finaldata,
      };

    case DELETE_SUB_CATEGORY:
      const _filterdata = state.subcategoryList.filter((data) => {
        return data.category_id !== payload;
      });
      return {
        ...state,
        subcategoryList: _filterdata,
      };

    case DELETE_ALL_SUB_CATEGORIES:
      return [];

    default:
      return state;
  }
}

export default subCategoryReducer;
