import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { SubCategoriesTable } from "../components/Tables";
import { RoutesCustom } from "../routescustom";
import { Modal } from "@themesberg/react-bootstrap";
import { SubCategoryForm } from "./forms/SubCategoryForm";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  GetAllSubCategories,
  GetSubCategoryById,
} from "../actions/actionCreaters/subCategoryActionCreater";

const SubCategories = (props) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const editobj = useSelector((state) => state.subCategory.subCategoryObj);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const [isedit, iseditchange] = useState(false);
  const [title, titlechange] = useState("Create Sub Category");

  const handleEdit = (id) => {
    iseditchange(true);
    titlechange("Update Sub Category");
    setShowDefault(true);
    dispatch(GetSubCategoryById(id));
    // alert(id)
  };

  const handleShowSubCategoryView = (id) => {
    // dispatch(GetCategoryById(id));

    navigate(RoutesCustom.SubCategoriesView.path, {
      state: { sub_category_id: id },
    });
  };

  useEffect(() => {
    props.loadsubCategories(location.state.category_id);
  }, []);

  return props.subCategorystate.isloading ? (
    <div>
      <h2>Loading.....</h2>
    </div>
  ) : props.subCategorystate.errormessage ? (
    <div>
      <h2>{props.subCategorystate.errormessage}</h2>
    </div>
  ) : (
    <>
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">{title}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <SubCategoryForm
            isEdit={isedit}
            category_id={location.state.category_id}
          />
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <Link to={RoutesCustom.DashboardOverview.path}>
                {" "}
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {" "}
              <Link to={RoutesCustom.categories.path}>Discover</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>SubCategories</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Sub Categories</h4>
          <p className="mb-0">Add sub categories to add discover places.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setShowDefault(true)}
            >
              Add New
            </Button>
            {/* <Button variant="outline-primary" size="sm">
              Export
            </Button> */}
          </ButtonGroup>
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end"></Col>
        </Row>
      </div>

      <SubCategoriesTable
        handleEdit={handleEdit}
        handleShowSubCategoryView={handleShowSubCategoryView}
      />
    </>
  );
};

const mapDispatchtoProps = (dispatch) => {
  return {
    loadsubCategories: (category_id) =>
      dispatch(GetAllSubCategories(category_id)),
  };
};
const mapStatetoProps = (state) => {
  return {
    subCategorystate: state.subCategory,
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(SubCategories);
