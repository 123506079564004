import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateSubCategory,
  UpdateSubCategory,
} from "../../actions/actionCreaters/subCategoryActionCreater";

export const SubCategoryForm = ({ isEdit, category_id }) => {
  const dispatch = useDispatch();

  const editobj = useSelector((state) => state.subCategory.subCategoryObj);

  const [subCategoryFormData, setSubCategoryFormData] = useState({
    sub_category_name: "",
  });

  const handlesubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("sub_category_name", subCategoryFormData.sub_category_name);

    console.log(formData);
    if (isEdit != false) {
      // formData.append("sub_category_id", editobj[0].sub_category_id);
      dispatch(UpdateSubCategory(formData,editobj[0].sub_category_id));
    } else {
      formData.append("category_id", category_id);

      dispatch(CreateSubCategory(formData));
    }
    // closepopup();
  };

  const handleChange = (e) => {
    setSubCategoryFormData({
      ...subCategoryFormData,
      [e.target.name]: e.target.value,
    });
  };
  
  useEffect(() => {
    if (Object.keys(editobj).length > 0) {
      // categoryFormData.category_name = editobj[0].category_name;
      setSubCategoryFormData({
        sub_category_name: editobj[0].sub_category_name,
      });
    } else {
      // clearstate();
    }
  }, [editobj]);

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4 w-100">
        <Card.Body>
          {/* <h5 className="mb-4">General information</h5> */}
          <Form onSubmit={handlesubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="subCategory">
                  <Form.Label>Sub category Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your Sub category Name"
                    name="sub_category_name"
                    value={subCategoryFormData.sub_category_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3">
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default SubCategoryForm;
