import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faExternalLinkAlt,
  faEye,
  faPlaceOfWorship,
  faPlus,
  faRemoveFormat,
  faRunning,
  faStepForward,
  faTrashAlt,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "@themesberg/react-bootstrap";
import { RoutesCustom } from "../routescustom";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import transactions from "../data/transactions";
import commands from "../data/commands";
import DiscoverPlacesForm from "../pages/forms/DiscoverPlacesForm";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSubCategoryById,
  RemoveSubCategory,
} from "../actions/actionCreaters/subCategoryActionCreater";
import { RemoveFAQ } from "../actions/actionCreaters/faqActionCreater";
import FAQsForm from "../pages/forms/FaqsForm";
import {
  GetContactUsById,
  RemoveContactUs,
} from "../actions/actionCreaters/contactUsActionCreater";
import { PreviewContactUsWidget, PreviewHeritageStepWidget } from "./Widgets";
import { baseUrl, imgBaseUrl } from "../services/api";
import HeritageWalkForm from "../pages/forms/HeritageWalkForm";
import { RemoveHeritageWalk } from "../actions/actionCreaters/heritageWalkActionCreater";
import HeritageWalkStepsForm from "../pages/forms/HeritageWalkStepsForm";
import {
  GetAllHeritageWalkSteps,
  GetHeritageWalkStepById,
  RemoveHeritageWalkStep,
} from "../actions/actionCreaters/heritageWalkStepsActionCreater";
import moment from "moment-timezone";

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon
            icon={bounceIcon}
            className={`${bounceTxtColor} me-3`}
          />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map((pv) => (
            <TableRow key={`page-visit-${pv.id}`} {...pv} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const {
      id,
      source,
      sourceIcon,
      sourceIconColor,
      sourceType,
      category,
      rank,
      trafficShare,
      change,
    } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">
            {id}
          </Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon
            icon={sourceIcon}
            className={`icon icon-xs text-${sourceIconColor} w-30`}
          />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar
                variant="primary"
                className="progress-lg mb-0"
                now={trafficShare}
                min={0}
                max={100}
              />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map((pt) => (
              <TableRow key={`page-traffic-${pt.id}`} {...pt} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const {
      country,
      countryImage,
      overallRank,
      overallRankChange,
      travelRank,
      travelRankChange,
      widgetsRank,
      widgetsRankChange,
    } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image
              src={countryImage}
              className="image-small rounded-circle me-2"
            />
            <div>
              <span className="h6">{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{overallRank ? overallRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">{travelRank ? travelRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">{widgetsRank ? widgetsRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map((r) => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = () => {
  const totalTransactions = transactions.length;

  const TableRow = (props) => {
    const { invoiceNumber, subscription, price, issueDate, dueDate, status } =
      props;
    const statusVariant =
      status === "Paid"
        ? "success"
        : status === "Due"
        ? "warning"
        : status === "Canceled"
        ? "danger"
        : "primary";

    return (
      <tr>
        <td>
          <Card.Link
            as={Link}
            to={RoutesCustom.Invoice.path}
            className="fw-normal"
          >
            {invoiceNumber}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{subscription}</span>
        </td>
        <td>
          <span className="fw-normal">{issueDate}</span>
        </td>
        <td>
          <span className="fw-normal">{dueDate}</span>
        </td>
        <td>
          <span className="fw-normal">${parseFloat(price).toFixed(2)}</span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>{status}</span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add new
                places
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Bill For</th>
              <th className="border-bottom">Issue Date</th>
              <th className="border-bottom">Due Date</th>
              <th className="border-bottom">Total</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((t) => (
              <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const SubCategoriesTable = ({
  handleEdit,
  handleShowSubCategoryView,
}) => {
  const subCategory = useSelector((state) => state.subCategory.subcategoryList);
  const dispatch = useDispatch();
  const totalTransactions = transactions.length;
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [subCatId, setSubCatId] = useState(null);
  const handleEditData = (id) => {
    // {handleEdit};
    // dispatch(GetSubCategoryById(id));
  };

  const handleDeleteBtn = () => {
    // {handleEdit};
    dispatch(RemoveSubCategory(subCatId));
    handleCloseDelete();
  };
  const handleDelete = (id) => {
    setShowDelete(true);
    setSubCatId(id);
  };

  const TableRow = (props) => {
    const { id, sub_category_id, sub_category_name, createdAt } = props;

    return (
      <tr>
        <td>
          <Card.Link
            as={Link}
            to={RoutesCustom.Invoice.path}
            className="fw-normal"
          >
            {id}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
            {" "}
            <a onClick={() => handleShowSubCategoryView(sub_category_id)}>
              {sub_category_name}
            </a>{" "}
          </span>
        </td>
        <td>
          <span className="fw-normal"> {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}</span>
        </td>

        <td>
          {/* <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setShowDefault(true);
                  setSubCatId(sub_category_id);
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add new
                places
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleShowSubCategoryView(sub_category_id)}
              >
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleEdit(sub_category_id)}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => handleDelete(sub_category_id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <pre>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => {
                setShowDefault(true);
                setSubCatId(sub_category_id);
              }}
            >
              <FontAwesomeIcon icon={faPlaceOfWorship} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => handleShowSubCategoryView(sub_category_id)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => handleEdit(sub_category_id)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="me-2"
              onClick={() => handleDelete(sub_category_id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Sub Categories</th>
              <th className="border-bottom">Created On</th>

              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {subCategory ? null : (
              <td colSpan={4}>
                <tr>No sub categories is added</tr>
              </td>
            )}

            {subCategory.map((row, i) => (
              <TableRow key={`transaction-${row.id}`} {...row} />
            ))}
          </tbody>
        </Table>
        {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer> */}
      </Card.Body>{" "}
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Create New Place</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <DiscoverPlacesForm isedit={false} subCatId={subCatId} />
        </Modal.Body>
      </Modal>
      {/* delete Dialog */}
      <Modal
        as={Modal.Dialog}
        centered
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Header>
          <Modal.Title className="h6">Are your sure ?</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>Once delete it will not be able to recover.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" aria-label="Yes" onClick={handleDeleteBtn}>
            Yes
          </Button>
          <Button
            variant="outline-primary"
            aria-label="No"
            onClick={handleCloseDelete}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export const HeritageWalkTable = ({ handleEdit }) => {
  const heritageWalks = useSelector(
    (state) => state.heritageWalk.heritageWalkList
  );

  const dispatch = useDispatch();
  const totalHeritageWalks = heritageWalks.length;
  const [showDefault, setShowDefault] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [heritageId, setHeritageWalkId] = useState(null);

  let navigate = useNavigate();

  const handleDeleteBtn = () => {
    dispatch(RemoveHeritageWalk(heritageId));
    handleCloseDelete();
  };
  const handleDelete = (id) => {
    setShowDelete(true);
    setHeritageWalkId(id);
  };

  const handleShowHeritageWalkView = (heritageId) => {
    navigate(RoutesCustom.heritageWalkSteps.path, {
      state: { heritage_id: heritageId },
    });
  };

  const TableRow = (props) => {
    const {
      id,
      heritage_id,
      place_name,
      place_img,
      center_longitude,
      center_latitude,
      province,
      district,
      municipality,
      ward,
      createdAt,
    } = props;

    return (
      <tr>
        <td>
          <Card.Link
            as={Link}
            to={RoutesCustom.Invoice.path}
            className="fw-normal"
          >
            {id}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
            <Image
              height={32}
              width={32}
              fluid
              rounded
              src={imgBaseUrl + place_img}
              className="m-1"
            />
            <a onClick={() => handleShowHeritageWalkView(heritage_id)}>
              {place_name}
            </a>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {province},&nbsp;{district},&nbsp;{municipality}&nbsp;-{ward}&nbsp;{" "}
          </span>
        </td>
        <td>
          <span className="fw-normal"> {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}</span>
        </td>

        <td>
          <pre>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => {
                handleShowHeritageWalkView(heritage_id);
              }}
            >
              <FontAwesomeIcon icon={faWalking} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => handleShowHeritageWalkView(heritage_id)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => handleEdit(heritage_id)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="me-2"
              onClick={() => handleDelete(heritage_id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Heritage</th>
              <th className="border-bottom">Location</th>
              <th className="border-bottom">Created On</th>

              <th className="border-bottom">Actions</th>
            </tr>
          </thead>
          <tbody>
            {heritageWalks ? null : (
              <td colSpan={4}>
                <tr>No heritage walk place is added</tr>
              </td>
            )}
            {heritageWalks.length > 0 &&
              heritageWalks.map((row, i) => (
                <TableRow key={`transaction-${row.id}`} {...row} />
              ))}
          </tbody>
        </Table>
      </Card.Body>{" "}
      {/* delete Dialog */}
      <Modal
        as={Modal.Dialog}
        centered
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Header>
          <Modal.Title className="h6">Are your sure ?</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>Once delete it will not be able to recover.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" aria-label="Yes" onClick={handleDeleteBtn}>
            Yes
          </Button>
          <Button
            variant="outline-primary"
            aria-label="No"
            onClick={handleCloseDelete}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export const HeritageWalkStepsTable = () => {
  const heritageWalkStepsList = useSelector(
    (state) => state.heritageWalkSteps.heritageWalkStepList
  );
  const heritageWalkDetail = useSelector(
    (state) => state.heritageWalkSteps.heritageWalkObj
  );
  const heritageWalkStepIsLoading = useSelector(
    (state) => state.heritageWalkSteps.isloading
  );

  const dispatch = useDispatch();
  const totalHeritageWalks = heritageWalkStepsList.length;

  const [showDefault, setShowDefault] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const handleClose = () => {
    setShowDefault(false);
    setShowPreview(false);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [heritageStepId, setHeritageStepWalkId] = useState(null);

  let navigate = useNavigate();

  const [isedit, iseditchange] = useState(false);
  const [title, titlechange] = useState("Create Heritage Walk Steps");

  const handleViewHeritageStep = (id) => {
    setShowPreview(true);
    dispatch(GetHeritageWalkStepById(id));
  };

  const handleEdit = (id) => {
    iseditchange(true);
    titlechange("Update Heritage Walk Steps");
    setShowDefault(true);
    dispatch(GetHeritageWalkStepById(id));
  };

  const handleAdd = () => {
    iseditchange(false);
    titlechange("Create Heritage Walk Steps");
    setShowDefault(true);
  };

  const handleDeleteBtn = () => {
    dispatch(RemoveHeritageWalkStep(heritageStepId));
    handleCloseDelete();
  };
  const handleDelete = (id) => {
    setShowDelete(true);
    setHeritageStepWalkId(id);
  };

  const closepopup = () => {
    setShowDefault(false);
  };

  const TableRow = (props) => {
    const { id, heritage_step_id, step_name, longitude, latitude, createdAt } =
      props;

    return (
      <tr>
        <td>
          <Card.Link
            as={Link}
            to={RoutesCustom.Invoice.path}
            className="fw-normal"
          >
            {id}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{step_name}</span>
        </td>
        <td>
          <span className="fw-normal">
            {longitude},&nbsp;{latitude}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </span>
        </td>

        <td>
          <pre>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => handleViewHeritageStep(heritage_step_id)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => handleEdit(heritage_step_id)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="me-2"
              onClick={() => handleDelete(heritage_step_id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
            {props.index + 1 == totalHeritageWalks ? (
              <Button
                variant="primary"
                size="sm"
                className="me-2"
                onClick={() => {
                  handleAdd();
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            ) : (
              <></>
            )}
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Heritage</th>
              <th className="border-bottom">Location</th>
              <th className="border-bottom">Created On</th>
              <th className="border-bottom">Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* {heritageWalkSteps.length} */}
            {heritageWalkStepsList.length == 0 && (
              <td colSpan={5}>
                <center>
                  <h6> No heritage walk steps are added</h6>
                  <Button
                    variant="secondary"
                    size="sm"
                    className="me-2 m-2"
                    onClick={() => {
                      setShowDefault(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faWalking} />
                    &nbsp; Add New Steps
                  </Button>
                </center>
              </td>
            )}
            {heritageWalkStepsList.length > 0 &&
              heritageWalkStepsList.map((row, i) => (
                <TableRow key={`transaction-${row.id}`} index={i} {...row} />
              ))}
          </tbody>
        </Table>
      </Card.Body>

      <Modal as={Modal.Dialog} centered show={showPreview} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Contact Us</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Col xs={12} xl={12}>
            <Row>
              <Col xs={12}>
                {/* {heritageWalkDetail} */}
                {heritageWalkStepIsLoading ? (
                  <div>
                    <h2>Loading.....</h2>
                  </div>
                ) : (
                  <PreviewHeritageStepWidget
                    heritageDetail={heritageWalkDetail}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">{title}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <HeritageWalkStepsForm isEdit={isedit} closepopup={closepopup} />
        </Modal.Body>
      </Modal>
      {/* delete Dialog */}
      <Modal
        as={Modal.Dialog}
        centered
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Header>
          <Modal.Title className="h6">Are your sure ?</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>Once delete it will not be able to recover.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" aria-label="Yes" onClick={handleDeleteBtn}>
            Yes
          </Button>
          <Button
            variant="outline-primary"
            aria-label="No"
            onClick={handleCloseDelete}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export const FaqsTable = ({ handleEdit, handleShowSubCategoryView }) => {
  const faqs = useSelector((state) => state.faq.faqList);
  const dispatch = useDispatch();

  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [faqId, setFAQid] = useState(null);

  const handleDeleteBtn = () => {
    // {handleEdit};
    dispatch(RemoveFAQ(faqId));
    handleCloseDelete();
  };

  const handleDelete = (id) => {
    setShowDelete(true);
    setFAQid(id);
  };

  const TableRow = (props) => {
    const { id, faq_id, question, answer, createdAt } = props;

    return (
      <tr>
        <td>
          <Card.Link
            as={Link}
            to={RoutesCustom.Invoice.path}
            className="fw-normal"
          >
            {id}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
            {" "}
            <a onClick={() => handleShowSubCategoryView(faq_id)}>
              {question}
            </a>{" "}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {" "}
            <a onClick={() => handleShowSubCategoryView(faq_id)}>
              {answer}
            </a>{" "}
          </span>
        </td>
        <td>
          <span className="fw-normal"> {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}</span>
        </td>

        <td>
          {/* <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
         
              <Dropdown.Item onClick={() => handleEdit(faq_id)}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => handleDelete(faq_id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <pre>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => handleEdit(faq_id)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="me-2"
              onClick={() => handleDelete(faq_id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Question</th>
              <th className="border-bottom">Answer</th>
              <th className="border-bottom">CreatedAt</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {faqs && faqs ? null : (
              <td colSpan={4}>
                <tr>No FAQ is added</tr>
              </td>
            )}
            {faqs.length > 0 &&
              faqs.map((row, i) => (
                <TableRow key={`transaction-${row.id}`} {...row} />
              ))}
          </tbody>
        </Table>
      </Card.Body>{" "}
      {/* <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Create New Place</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <FAQsForm  isedit={true} faqId={faqId} />
        </Modal.Body>
      </Modal> */}
      {/* delete Dialog */}
      <Modal
        as={Modal.Dialog}
        centered
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Header>
          <Modal.Title className="h6">Are your sure ?</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>Once delete it will not be able to recover.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" aria-label="Yes" onClick={handleDeleteBtn}>
            Yes
          </Button>
          <Button
            variant="outline-primary"
            aria-label="No"
            onClick={handleCloseDelete}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export const ContactUsTable = ({ contactList }) => {
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const dispatch = useDispatch();

  const [contactId, setContactId] = useState(null);
  const contactDetail = useSelector((state) => state.contactUs.contactObj);
  const contactIsLoading = useSelector((state) => state.contactUs.isloading);

  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const handleViewContact = (id) => {
    setShowDefault(true);
    dispatch(GetContactUsById(id));
  };

  const handleDeleteBtn = () => {
    // {handleEdit};
    dispatch(RemoveContactUs(contactId));
    handleCloseDelete();
  };

  const handleDelete = (id) => {
    setShowDelete(true);
    setContactId(id);
  };

  const TableRow = (props) => {
    // const { name, usage = [], description, link } = props;
    const { id, contact_id, name, email, phone, createdAt } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{id}</code>
        </td>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: "50%" }}>
          <ul className="ps-0">
            {email}
            <br />
            {phone}
            <br />
            {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </ul>
        </td>
        {/* <td className="border-0" style={{ width: "20%" }}>
          <pre className="m-0 p-0">{description}</pre>
        </td> */}
        <td className="border-0" style={{ width: "10%" }}>
          <pre>
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => handleViewContact(contact_id)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="me-2"
              onClick={() => handleDelete(contact_id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body className="p-0">
          <Table
            responsive
            className="table-centered rounded"
            style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
          >
            <thead className="thead-light">
              <tr>
                <th className="border-0" style={{ width: "5%" }}>
                  #
                </th>
                <th className="border-0" style={{ width: "5%" }}>
                  Name
                </th>
                <th className="border-0" style={{ width: "50%" }}>
                  Detail
                </th>
                {/* <th className="border-0" style={{ width: "20%" }}>
              Message
            </th> */}
                <th className="border-0" style={{ width: "20%" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {contactList &&
                contactList.map((c) => (
                  <TableRow key={`command-${c.id}`} {...c} />
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Contact Us</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Col xs={12} xl={12}>
            <Row>
              <Col xs={12}>
                {contactIsLoading ? (
                  <div>
                    <h2>Loading.....</h2>
                  </div>
                ) : (
                  <PreviewContactUsWidget contactDetail={contactDetail} />
                )}
              </Col>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal
        as={Modal.Dialog}
        centered
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Header>
          <Modal.Title className="h6">Are your sure ?</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>Once delete it will not be able to recover.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" aria-label="Yes" onClick={handleDeleteBtn}>
            Yes
          </Button>
          <Button
            variant="outline-primary"
            aria-label="No"
            onClick={handleCloseDelete}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: "5%" }}>
          <ul className="ps-0">
            {usage.map((u) => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: "50%" }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: "40%" }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table
          responsive
          className="table-centered rounded"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: "5%" }}>
                Name
              </th>
              <th className="border-0" style={{ width: "5%" }}>
                Usage
              </th>
              <th className="border-0" style={{ width: "50%" }}>
                Description
              </th>
              <th className="border-0" style={{ width: "40%" }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map((c) => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
