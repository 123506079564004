import {
    MAKE_REQ,
    CREATE_FAQ,
    RETRIEVE_FAQS,
    UPDATE_FAQ,
    DELETE_FAQ,
    DELETE_ALL_FAQS,
    REQ_GETALL_SUCC,
    REQ_GETALL_FAIL,
    REQ_GETBYID_SUCC,
  } from "../actions/types/faqType";
  
  const initialState = {
    isloading: false,
    faqList: [],
    faqObj: {},
    errormessage: "",
  };
  
  function faqReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case MAKE_REQ:
        return {
          ...state,
          isloading: true,
        };
      case CREATE_FAQ:
        const _inputdata = { ...payload };
       
        return {
          ...state,
          faqList: [...state.faqList, _inputdata],
        };
      case REQ_GETBYID_SUCC:
        return {
          ...state,
          isloading: false,
          faqObj: payload,
        };
      case REQ_GETALL_SUCC:
        return {
          ...state,
          isloading: false,
          faqList: payload,
        };
  
      case REQ_GETALL_FAIL:
        return {
          ...state,
          isloading: false,
          companylist: [],
          errormessage: payload,
        };
  
      case RETRIEVE_FAQS:
        return {
          ...state,
          isloading: false,
          faqList: payload,
        };
  
      case UPDATE_FAQ:
        const _data = { ...action.payload };
        const _finaldata = state.faqList.map((item) => {
          return item.id === _data.id ? _data : item;
        });
        return {
          ...state,
          faqList: _finaldata,
        };
  
      case DELETE_FAQ:
        const _filterdata = state.faqList.filter((data) => {
          return data.category_id !== payload;
        });
        return {
          ...state,
          faqList: _filterdata,
        };
  
      case DELETE_ALL_FAQS:
        return [];
  
      default:
        return state;
    }
  }
  
  export default faqReducer;
  