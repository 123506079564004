import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Table,
  Image,
  Card,
  Breadcrumb,
  ButtonGroup,
  Modal,
} from "@themesberg/react-bootstrap";
import {
  ChoosePhotoWidget,
  PreviewContactUsWidget,
  ProfileCardWidget,
} from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";

import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { RoutesCustom } from "../routescustom";
import { Link, useNavigate } from "react-router-dom";
import { HeritageWalkTable, SubCategoriesTable } from "../components/Tables";
import { connect, useDispatch } from "react-redux";
import { HeritageWalkForm } from "./forms/HeritageWalkForm";
import { GetAllHeritageWalks, GetHeritageWalksById } from "../actions/actionCreaters/heritageWalkActionCreater";

const HeritageWalk = (props) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [isedit, iseditchange] = useState(false);
  const [title, titlechange] = useState("Create Heritage walk");
  const closepopup = () => {
    setShowDefault(false);
  };
  const handleEdit = (id) => {
    iseditchange(true);
    titlechange("Update Heritage Walk Place");
    setShowDefault(true);
    dispatch(GetHeritageWalksById(id));
  };

  useEffect(() => {
    props.loadHeritageWalk();
  }, []);

  return (props.heritageWalkState.isloading ? (
    <div>
      <h2>Loading.....</h2>
    </div>
  ) : props.heritageWalkState.errormessage ? (
    <div>
      <h2>{props.heritageWalkState.errormessage}</h2>
    </div>
  ) : <><Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title className="h6">{title}</Modal.Title>
      <Button variant="close" aria-label="Close" onClick={handleClose} />
    </Modal.Header>
    <Modal.Body>
      <HeritageWalkForm
        isEdit={isedit}
        closepopup={closepopup} 
      />
    </Modal.Body>
  </Modal>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <Link to={RoutesCustom.DashboardOverview.path}>
                {" "}
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Heritage walk</Breadcrumb.Item>
            {/* <Breadcrumb.Item active>SubCategories</Breadcrumb.Item> */}
          </Breadcrumb>
          <h4>Heritage Walk</h4>
          <p className="mb-0">Add heritage walk title and steps.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setShowDefault(true)}
            >
              Add New
            </Button>
            {/* <Button variant="outline-primary" size="sm">
              Export
            </Button> */}
          </ButtonGroup>
        </div>
      </div>
      <HeritageWalkTable handleEdit={handleEdit} />
    </>
  );
};
const mapDispatchtoProps = (dispatch) => {
  return {
    loadHeritageWalk: () => dispatch(GetAllHeritageWalks()),
  };
};
const mapStatetoProps = (state) => {
  return {
    heritageWalkState: state.heritageWalk,
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(HeritageWalk);