import { http, httpFile } from "../http-common";

class CategoryService {
  getAll() {
    return http.get("/category");
  }

  get(id) {
    return http.get(`/category/${id}`);
  }

  create(data) {
    return httpFile.post("/category", data);
  }

  update(id, data) {
    return httpFile.put(`/category/${id}`, data);
  }

  delete(id, imageLink) {
    // return http.delete(`/category/${id}/${imageLink}`);
    return httpFile.delete(`/category/${id}`);
  }

  deleteAll() {
    return http.delete(`/category`);
  }

  findByTitle(title) {
    return http.get(`/category?title=${title}`);
  }
}

export default new CategoryService();
