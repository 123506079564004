// https://en.wikipedia.org/wiki/List_of_districts_of_Nepal

const province = [
  { id: 1, name: "Koshi", value: "Koshi" },
  { id: 2, name: "Madhesh", value: "Madhesh" },
  { id: 3, name: "Bagmati", value: "Bagmati" },
  { id: 4, name: "Gandaki", value: "Gandaki" },
  { id: 5, name: "Lumbini", value: "Lumbini" },
  { id: 6, name: "Karnali", value: "Karnali" },
  { id: 7, name: "Sudurpashchim", value: "Sudurpashchim" },
];

const districts = [
  { id: 1, name: "Sinduli", value: "Sinduli", province: "Bagmati" },
  { id: 2, name: "Ramechhap", value: "Ramechhap", province: "Bagmati" },
  { id: 3, name: "Dolakha", value: "Dolakha", province: "Bagmati" },
  { id: 4, name: "Bhaktapur", value: "Bhaktapur", province: "Bagmati" },
  { id: 5, name: "Dhading", value: "Dhading", province: "Bagmati" },
  { id: 6, name: "Kathmandu", value: "Kathmandu", province: "Bagmati" },
  {
    id: 7,
    name: "Kavrepalanchok",
    value: "Kavrepalanchok",
    province: "Bagmati",
  },
  { id: 8, name: "Lalitpur", value: "Lalitpur", province: "Bagmati" },
  { id: 9, name: "Nuwakot", value: "Nuwakot", province: "Bagmati" },
  { id: 10, name: "Rasuwa", value: "Rasuwa", province: "Bagmati" },
  {
    id: 11,
    name: "Sindhupalchok",
    value: "Sindhupalchok",
    province: "Bagmati",
  },
  { id: 12, name: "Chitwan", value: "Chitwan", province: "Bagmati" },
  { id: 13, name: "Makwanpur", value: "Makwanpur", province: "Bagmati" },
];

const municipalities = [
  {
    id: 1,
    name: "Kathmandu Metropolitian",
    value: "Kathmandu Metropolitian",
    district: "Kathmandu",
  },
  {
    id: 2,
    name: "Budhanilkantha",
    value: "Budhanilkantha",
    district: "Kathmandu",
  },
  { id: 3, name: "Tarakeshwar", value: "Tarakeshwar", district: "Kathmandu" },
  { id: 4, name: "Gokarneshwar", value: "Gokarneshwar", district: "Kathmandu" },
  { id: 5, name: "Chandragiri", value: "Chandragiri", district: "Kathmandu" },
  { id: 6, name: "Tokha", value: "Tokha", district: "Kathmandu" },
  {
    id: 7,
    name: "Kageshwari-Manohara",
    value: "Kageshwari-Manohara",
    district: "earth",
  },
  { id: 8, name: "Nagarjun", value: "Nagarjun", district: "Kathmandu" },
  { id: 9, name: "Kirtipur", value: "Kirtipur", district: "Kathmandu" },
];
const wards = [
  { id: 1, name: "1", value: "1" },
  { id: 2, name: "2", value: "2" },
  { id: 3, name: "3", value: "3" },
  { id: 4, name: "4", value: "4" },
  { id: 5, name: "5", value: "5" },
  { id: 6, name: "6", value: "6" },
  { id: 7, name: "7", value: "7" },
  { id: 8, name: "8", value: "8" },
  { id: 9, name: "10", value: "10" },
  { id: 10, name: "11", value: "11" },
  { id: 11, name: "12", value: "12" },
  { id: 13, name: "13", value: "13" },
  { id: 14, name: "14", value: "14" },
];

export { province, districts, municipalities, wards };
